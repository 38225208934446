<template>
  <!-- jubu -->
  <div class="jubu">
    <div class="t1 bgwhite flex_items flex_center">
      <div class="sub mt30 mb30">
        <p class="bold f28">住了很久的爱家麻烦不断</p>
        <p class="mt20 f20">又不能拆了重建，我该怎么办?</p>
      </div>
    </div>
    <div class="beijing2 pos mt30">
      <img
        :src="imgUrl + 'product/jubu/tu1.png'"
        alt=""
        class="abs"
        style="left: 30px; width: 400px; height: 570px; bottom: 0; z-index: 6"
      />
      <el-image
        style="width: 100%; height: 450px"
        :src="imgUrl + 'product/jubu/beijing2.png'"
        fit="cover"
      ></el-image>
    </div>
    <!-- t3 -->
    <div class="t3 flex_column flex_items">
      <div class="sub mt90">
        <p class="bold f28">旧房改造三大痛点</p>
        <p class="mt20 f20">您的痛苦我们都懂，居家烦恼交给益鸟</p>
      </div>
      <div class="sub2 flex_between flex_items mt30">
        <div class="t3l flex_column">
          <div
            v-for="(item, index) in tongs"
            :key="index"
            class="t3why p10 mt20"
          >
            <p class="bold f24 f-left">{{ item.t1 }}</p>
            <p class="f16 f-left mt10">
              {{ item.t2 }}
            </p>
          </div>
        </div>
        <div class="t3l">
          <img :src="imgUrl + 'product/jubu/tu2.png'" alt="" class="tu2" />
        </div>
      </div>
    </div>
    <!-- t4 -->
    <div class="t4 flex_center flex_items">
      <div class="sub flex_column flex_items" style="color: white">
        <p class="f30">任何空间想改就改，麻烦给我，美好给您</p>
        <p class="f15 mt20">
          不搬家/不动手/不麻烦，把家交给益鸟，还您一个温馨新房
        </p>
      </div>
    </div>
    <!-- 主要改造空间 -->
    <div class="t1 bgwhite flex_items flex_center">
      <div class="sub mt30 mb30">
        <p class="bold f28 mt20">主要改造空间</p>
        <p class="mt20 f20">更多改造，更多美好~等你探索</p>
      </div>
    </div>
    <!-- lun -->
    <div class="flex_center flex_items bgwhite pb20">
      <div class="sub flex_start flex_items">
        <div
          v-for="(item, index) in gais"
          :key="index"
          :class="[
            'f-center',
            'ptb5',
            'f14',
            'mr30',
            gai == index ? 'gainav' : 'nogai',
          ]"
          
          @mouseover="changegainav(index)"
          @mouseout="carouseMouseleave()"
        >
          {{ item.nav }}
        </div>
      </div>
    </div>
    <div class="lun">
      <el-carousel
        :interval="2000"
        arrow="always"
        @change="gaichange"
        ref="carousel"
        :autoplay="autoplay"
      >
        <el-carousel-item
          v-for="(item, index) in gais"
          :key="index"
          name="index"
        >
          <div class="pos">
            <el-image
              style="width: 100%; height: 700px"
              :src="item.url"
              fit="cover"
              class=""
            ></el-image>
            <div
              class="abs flex_center flex_items f20"
              style="
                bottom: 0;
                left: 0;
                width: 100%;
                height: 60px;
                color: white;
                background: rgba(10, 9, 8, 0.4);
              "
            >
              <div class="flex_around flex_items">
                <div class="ling mr10"></div>
                <p class="mr10">{{ item.title }}</p>
                <div class="ling"></div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 最受欢迎案例 -->
    <div class="bgwhite flex_item flex_center">
      <div class="sub" style="margin-top: 50px; margin-bottom: 50px">
        <p class="bold f28">我们过去最受欢迎案例</p>
      </div>
    </div>
    <div class="flex_start flex_items" style="width: 100%">
      <div class="flex_column flex_items mr5" style="width: 32%">
        <div class="pos">
          <el-image :src="anlis[0].src" fit="cover" class="anli1"> </el-image>
          <div
            @mouseleave="chutu(0)"
            @mouseenter="yitu(0)"
            :class="[
              'abs',
              'flex_center',
              'flex_items',
              'f24',
              anlis[0].zt == 1 ? 'selecttu' : 'noselecttu',
            ]"
            style="color: white"
          >
            <div
              :class="[
                'flex_center',
                'flex_items',
                anlis[0].zt == 1 ? 'xianinner' : 'noxianinner',
              ]"
              style="border: 1px solid white"
            >
              <p>{{ anlis[0].title }}</p>
            </div>
          </div>
        </div>
        <div class="pos">
          <el-image :src="anlis[1].src" fit="cover" class="anli2"> </el-image>
          <div
            @mouseleave="chutu(1)"
            @mouseenter="yitu(1)"
            :class="[
              'abs',
              'flex_center',
              'flex_items',
              'f24',
              anlis[1].zt == 1 ? 'selecttu1' : 'noselecttu1',
            ]"
            style="color: white"
          >
            <div
              :class="[
                'flex_center',
                'flex_items',
                anlis[1].zt == 1 ? 'xianinner1' : 'noxianinner1',
              ]"
              style="border: 1px solid white"
            >
              <p>{{ anlis[1].title }}</p>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 40%" class="pos">
        <el-image :src="anlis[2].src" fit="cover" class="anli3"> </el-image>
        <div
          @mouseleave="chutu(2)"
          @mouseenter="yitu(2)"
          :class="[
            'abs',
            'flex_center',
            'flex_items',
            'f24',
            anlis[2].zt == 1 ? 'selecttu2' : 'noselecttu2',
          ]"
          style="color: white"
        >
          <div
            :class="[
              'flex_center',
              'flex_items',
              anlis[2].zt == 1 ? 'xianinner2' : 'noxianinner2',
            ]"
            style="border: 1px solid white"
          >
            <p>{{ anlis[2].title }}</p>
          </div>
        </div>
      </div>
      <div style="width: 28%" class="pos ml5">
        <el-image :src="anlis[3].src" fit="cover" class="anli4"> </el-image>
        <div
          @mouseleave="chutu(3)"
          @mouseenter="yitu(3)"
          :class="[
            'abs',
            'flex_center',
            'flex_items',
            'f24',
            anlis[3].zt == 1 ? 'selecttu2' : 'noselecttu2',
          ]"
          style="color: white"
        >
          <div
            :class="[
              'flex_center',
              'flex_items',
              anlis[3].zt == 1 ? 'xianinner2' : 'noxianinner2',
            ]"
            style="border: 1px solid white"
          >
            <p>{{ anlis[3].title }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户评价 -->
    <div class="kehu pos">
      <div class="flex_item flex_center">
        <div class="sub mb30" style="padding-top: 100px">
          <p class="bold f28 mt20 f-center">改造客户好评</p>
          <p class="mt20 f20 f-center">大众点评金牌改造商家</p>
        </div>
      </div>
      <div class="flex_item flex_center">
        <div class="sub flex_between">
          <div>
            <el-image
              fit="contain"
              :src="imgUrl + 'product/jubu/haoping.png'"
              class="haopin"
            ></el-image>
          </div>
          <div class="p10 bgwhite flex_column flex_items mt40 yuk">
            <p class="f22 bold mt10">立即预约 极速上门检测</p>
            <p class="mt10 f12">
              已经有100000+位业主选择我们，并完成了房屋维修
            </p>
            <div class="flex_column" style="color: white; width: 290px">
              <el-input
                placeholder="您的姓名"
                prefix-icon="el-icon-user-solid"
                v-model="formInline.user"
                class="mt10 inin"
              >
              </el-input>
              <el-input
                placeholder="手机号码"
                prefix-icon="el-icon-phone"
                v-model="formInline.phone"
                class="mt10 inin"
              >
              </el-input>
              <el-button
                @click="onSubmit"
                type="warning"
                class="mt10"
                style="margin-top: 15px"
                >立即预约</el-button
              >
              <div
                class="p10 flex_start flex_items"
                style="color: white; background: #007B46; margin-top: 40px"
              >
                <img
                  class="mr5"
                  :src="imgUrl + 'product/jubu/dianhua.png'"
                  alt=""
                  style="width: 60px; height: 60px"
                />
                <div class="flex_column">
                  <p class="f14 f-left">客服咨询热线</p>
                  <p class="f22 bold">4000-987-009</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 省心 -->
    <div class="sheng" style="color: white">
      <div class="flex_item flex_center">
        <div class="sub mb30" style="padding-top: 40px">
          <p class="bold f32 mt20 f-center">省心给你，麻烦给我</p>
          <p class="mt20 f20 f-center">透明施工管控，真实看得见</p>
        </div>
      </div>
      <div class="flex_item flex_center">
        <div class="sub flex_start flex_items">
          <div class="flex_column">
            <div class="flex_start">
              <img
                :src="imgUrl + 'product/jubu/weixin1.png'"
                alt=""
                class="weiwei mr20"
              />
              <img
                :src="imgUrl + 'product/jubu/weixin.png'"
                alt=""
                class="weiwei mr20"
              />
            </div>
            <div class="flex_column mt20">
              <p class="bold f24 f-left">微信群管理</p>
              <p class="mt10 f15 f-left">
                自合同签订开始建立微信群，每天在群里播报施工进度和使用材料，让客户<br />不用到施工现场也能全面了解施工过程和所用正品材料
              </p>
            </div>
          </div>
          <div class="flex_column" style="margin-left: 100px">
            <img
              :src="imgUrl + 'product/jubu/yonghuduan.png'"
              alt=""
              class="weiwei"
            />
            <div class="flex_column">
              <p class="bold f20 f-left mt20">用户端小程序查看施工流程</p>
              <p class="mt10 f15 f-left">
                用户可以通过益鸟美居用户小程序，查看施工进度，保留施工现场的所有施工素材，更透明更可靠，让质保有意义
              </p>
            </div>
          </div>
          <div class="flex_column" style="margin-left: 50px">
            <img
              :src="imgUrl + 'product/jubu/gongrenapp.png'"
              alt=""
              class="weiwei"
            />
            <div class="flex_column mt20">
              <p class="bold f24 f-left">工人APP上传施工数据</p>
              <p class="mt10 f15 f-left">
                产业工人严格按照施工流程进行施工安排和施工图片与视频的上传，透明可靠
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "wot-design";
export default {
  data() {
    return {
      autoplay:true,
      imgUrl: this.util.imgUrl,
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
      },
      tongs: [
        {
          t1: "装修公司接不了",
          t2:
            "一般客单低于2万元，他们是不愿意接的。不愿接不是他们不想做，而是做不了!这是由装修公司的组织模式决定的，一个10万左右的装修单子，管理人员、施工人员和材料都好组织，与之相反则比较麻烦。",
        },
        {
          t1: "桥头堡、游击队做不好",
          t2:
            "游击队仅限于单一服务项目，对于局部改造需要多工种协同，只能另找师傅支持，多工种协调效率低价格高，售后无质保，容易引起责任不明确纠纷。",
        },
        {
          t1: "局部改造工期有烦恼",
          t2:
            "客户有改造需求，但觉得麻烦，一是改造会使得施工期间家里的居住环境变得糟糕；二是工期还长，需要忍受很长一段时间，因为施工团队工种单一，需要多方面协调，师傅的组织和施工安排完全由师傅决定，工期无法保证。",
        },
      ],
      gais: [
        {
          nav: "厨房改造",
          url: this.util.imgUrl + "product/jubu/chufanggaizao.png",
          title: "极速厨房翻新，施工仅需一个周末",
        },
        {
          nav: "卫生间改造",
          url: this.util.imgUrl + "product/jubu/weishengjian1.jpg",
          title: "多点联防，全方位防水防臭",
        },
        {
          nav: "背景墙改造",
          url: this.util.imgUrl + "product/jubu/dianshibeijingqiang1.jpg",
          title: "个性化打造，尽显生活品质",
        },
        {
          nav: "阳台改造",
          url: this.util.imgUrl + "product/jubu/yangtaigaizao1.jpg",
          title: "阳台改得好，多出一间房",
        },
        {
          nav: "客厅阳台扩空间",
          url: this.util.imgUrl + "product/jubu/ketingyangtai1.jpg",
          title: "空间联通，光满厅房",
        },
        {
          nav: "生活阳台优体验",
          url: this.util.imgUrl + "product/jubu/shenghuoyangtai1.jpg",
          title: "充分利用空间，小阳台逆天改命",
        },
        {
          nav: "儿童房改造",
          url: this.util.imgUrl + "product/jubu/ertongfang1.jpg",
          title: "空间扮靓，打造萌娃城堡",
        },
      ],
      gai: 0,
      anlis: [
        {
          title: "卫生间改造",
          src: this.util.imgUrl + "product/jubu/anli1.png",
          zt: 0,
        },
        {
          title: "背景墙改造",
          src: this.util.imgUrl + "product/jubu/anli2.png",
          zt: 0,
        },
        {
          title: "厨房改造",
          src: this.util.imgUrl + "product/jubu/anli3.png",
          zt: 0,
        },
        {
          title: "生活阳台优体验",
          src: this.util.imgUrl + "product/jubu/anli4.png",
          zt: 0,
        },
      ],
    };
  },
  created() {
    this.$store.commit("setpage", "产品中心");
  },
  methods: {
    gaichange(val) {
      this.gai = val;
    },
    changegainav(idx) {
      this.autoplay=false
      this.gai = idx;
      this.$refs.carousel.setActiveItem(idx);
    },
    carouseMouseleave(){
      this.autoplay=true
    },
    yitu(idx) {
      this.anlis[idx].zt = 1;
    },
    chutu(idx) {
      this.anlis[idx].zt = 0;
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 7,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
  },
};
</script>

<style>
.sub {
  max-width: 1200px;
}
.sub2 {
  max-width: 1300px;
}
.beijing2 {
  width: 100%;
  height: 450px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/jubu/beijing2.png")
    no-repeat;
}
.t3 {
  width: 100%;
  height: 1000px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/jubu/beijing3.png")
    no-repeat;
}
.t3l {
  width: 48%;
}
.t3why {
  border: 10px solid #d7ba7e;
  background: white;
  height: 136px;
}
.tu2 {
  width: 100%;
  height: 570px;
  margin-top: 24px;
}
.t4 {
  width: 100%;
  height: 260px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/jubu/beijing4.png")
    no-repeat;
}
.gainav {
  border-radius: 18px;
  background: #06713d;
  color: white;
  width: 120px;
  cursor: pointer;
}
.nogai {
  border-radius: 18px;
  background: none;
  width: 120px;
  cursor: pointer;
  color: #000;
}
.ling {
  width: 15px;
  height: 15px;
  transform: rotateZ(45deg) skew(15deg, 15deg);
  background: #d7ba7e;
}
.lun .el-carousel__container {
  height: 593px;
}
.lun .el-carousel__indicators {
  display: none;
}
.anli1 {
  width: 100%;
  height: 327px;
}
.anli2 {
  width: 100%;
  height: 279px;
}
.anli3 {
  width: 100%;
  height: 610px;
}
.anli4 {
  width: 100%;
  height: 610px;
}
.xianinner {
  width: 96%;
  height: 305px;
  opacity: 1;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.noxianinner {
  width: 96%;
  height: 305px;
  opacity: 0;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.selecttu {
  background: rgba(10, 9, 8, 0.4);
  width: 100%;
  height: 325px;
  top: 0;
  left: 0;
  z-index: 5;
  cursor: pointer;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.noselecttu {
  background: rgba(10, 9, 8, 0);
  width: 100%;
  height: 325px;
  top: 0;
  left: 0;
  z-index: 5;
  cursor: pointer;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
/*  */
.xianinner1 {
  width: 96%;
  height: 255px;
  opacity: 1;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.noxianinner1 {
  width: 96%;
  height: 255px;
  opacity: 0;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.selecttu1 {
  background: rgba(10, 9, 8, 0.4);
  width: 100%;
  height: 275px;
  top: 0;
  left: 0;
  z-index: 5;
  cursor: pointer;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.noselecttu1 {
  background: rgba(10, 9, 8, 0);
  width: 100%;
  height: 275px;
  top: 0;
  left: 0;
  z-index: 5;
  cursor: pointer;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
/*  */
.xianinner2 {
  width: 96%;
  height: 590px;
  opacity: 1;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.noxianinner2 {
  width: 96%;
  height: 590px;
  opacity: 0;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.selecttu2 {
  background: rgba(10, 9, 8, 0.4);
  width: 100%;
  height: 610px;
  top: 0;
  left: 0;
  z-index: 5;
  cursor: pointer;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
.noselecttu2 {
  background: rgba(10, 9, 8, 0);
  width: 100%;
  height: 610px;
  top: 0;
  left: 0;
  z-index: 5;
  cursor: pointer;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear; /* Firefox 4 */
  -webkit-transition: all 0.2s linear; /* Safari 和 Chrome */
  -o-transition: all 0.2s linear; /* Opera */
}
/* end */
.kehu {
  width: 100%;
  height: 900px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/jubu/beijing5.png")
    no-repeat;
}
.haopin {
  width: 1000px;
  height: 600px;
}
.yuk {
  box-shadow: 0 0 4px #eeeeee;
  height: 374px;
}
.sheng {
  width: 100%;
  height: 1000px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/jubu/beijing6.png")
    no-repeat;
}
.weiwei {
  width: 255px;
  height: 502px;
}
</style>
