<template>
  <div class="newss">
    <el-backtop :visibility-height="60">
      <i class="el-icon-caret-top" style="margin: auto"></i>
    </el-backtop>
    <Header></Header>
    <!-- 下面 -->
    <div>
      <el-image
        :src="imgUrl + 'home/nb.png'"
        fit="cover"
        class="tt1"
      ></el-image>
      <!-- 搜索 -->
      <div class="ptb10 flex_center flex_items">
        <div class="flex_start" style="width: 1200px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>新闻资讯</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <!-- nav -->
      <div
        class="bgwhite flex_center flex_items bbe9s"
        style="margin-top: 15px"
      >
        <div class="flex_around flex_items" style="width: 1200px">
          <div
            @click="chosenav(item.category_id, index)"
            v-for="(item, index) in titles"
            :key="index"
            :class="[
              index == curt ? 'go' : 'nogo',
              'flex_center',
              'flex_items',
              'f14',
            ]"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="flex_center flex_items bgwhite pb40">
        <div class="flex_start" style="width: 1200px">
          <div style="width: 800px" :class="[lists.length == 1 ? 'only' : '']">
            <div
              v-if="lists.length != 0"
              class="flex_column mr20"
              v-loading="loading2"
            >
              <div
                :class="['flex_start', 'mt30', 'pos']"
                v-for="(item, index) in lists"
                :key="index"
                @click="newsdetail(item.id)"
                style="cursor: pointer"
              >
                <div class="xintu mr20">
                  <img
                    :src="tuhose + item.url"
                    alt=""
                    style="width: 300px; height: 200px"
                  />
                </div>
                <div class="flex_column">
                  <p class="f18 f-left">{{ item.title }}</p>
                  <p class="f14 mt10 bbe9s pb20 f-left" style="color: #8f8f8f">
                    {{ item.subtitle }}
                  </p>
                  <div
                    class="flex_between flex_items abs"
                    style="width: 450px; bottom: 0"
                  >
                    <div
                      class="flex_start flex_items f12"
                      style="color: #8f8f8f"
                    >
                      <span class="mr10">{{ item.updated_at }}</span>
                      <span class="" style="color: #007B46"
                        >{{ item.browse }}人浏览</span
                      >
                    </div>
                    <el-button
                      class=""
                      size="mini"
                      type="primary"
                      @click="newsdetail(item.id)"
                      style="
                        right: 0;
                        bottom: 0;
                        background: #007B46;
                        border: none;
                      "
                      >查看详情</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="f18 ptb20 flex_center flex_items"
              style="height: 500px"
            >
              <span>--暂无新闻信息--</span>
            </div>
          </div>
          <div class="pos">
            <div
              class="p10 bgwhite flex_column flex_items yuk abs"
              style="top: 100px"
            >
              <p class="f26 bold mt10">立即预约 极速上门检测</p>
              <p class="mt10 f12">
                已经有100000+位业主选择我们，并完成了房屋维修
              </p>
              <div class="flex_column" style="color: white; width: 290px">
                <el-input
                  placeholder="您的姓名"
                  prefix-icon="el-icon-user-solid"
                  v-model="formInline.user"
                  class="mt10 inin"
                >
                </el-input>
                <el-input
                  placeholder="手机号码"
                  prefix-icon="el-icon-phone"
                  v-model="formInline.phone"
                  class="mt10 inin"
                >
                </el-input>
                <el-button
                  @click="onSubmit"
                  type="warning"
                  class="mt10"
                  style="margin-top: 15px"
                  >立即预约</el-button
                >
                <div
                  class="p10 flex_start flex_items"
                  style="color: white; background: #007B46; margin-top: 40px"
                >
                  <img
                    class="mr5"
                    :src="imgUrl + 'product/jubu/dianhua.png'"
                    alt=""
                    style="width: 60px; height: 60px"
                  />
                  <div class="flex_column">
                    <p class="f14 f-left">客服咨询热线</p>
                    <p class="f22 bold">4000-987-009</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex_center flex_items pb40 bgwhite">
        <div v-if="lists.length != 0" class="flex_start mt20 flex_items">
          <span class="demonstration mr20">
            总共
            <span style="color: red">{{ total }}</span> 条数据</span
          >
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="20"
            layout="prev, pager, next"
            :current-page.sync="page"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import { Toast } from "wot-design";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      loading2: true,
      curt: 0,
      id: 13,
      page: 1,
      titles: [],
      lists: [],
      total: 0,
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
      },
    };
  },
  created() {
    this.$store.commit("setpage", "新闻资讯");
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/api/article/", {
          category_id: this.id,
          page: this.page,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.titles = res.data.category_arr;
            this.lists = res.data.articles.data;
            this.total = res.data.articles.total;
            this.loading2 = false;
          }
        });
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 10,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
    chosenav(item, index) {
      this.curt = index;
      this.id = item;
      this.page = 1;
      this.loading2 = true;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.loading2 = true;
      this.getData();
    },
    newsdetail(id) {
      this.$router.push({
        path: "/newsdetail",
        query: {
          id: id,
        },
      });
    },
  },
  components: {
    Header: header,
    Footer: footer,
  },
};
</script>
<style>
.newss .tt1 {
  width: 100%;
  height: 700px;
}
.go {
  background: url("https://imgaes.yiniaoweb.com/gw/home/xuan.png");
  width: 90px;
  height: 51px;
  background-size: cover;
  color: #7b6126;
  font-weight: bold;
  cursor: pointer;
}
.nogo {
  background: none;
  width: 90px;
  height: 51px;
  font-weight: bold;
  cursor: pointer;
}
.newss .xintu {
  width: 300px;
  height: 200px;
}
.newss .el-loading-spinner {
  top: 5% !important;
}
.only {
  height: 470px;
}
</style>
