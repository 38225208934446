<template>
  <div class="fangshui bgwhite" style="padding-top: 106px">
    <div class="t1 bgwhite flex_items flex_center flex_column">
      <div class="sub mt30 mb30">
        <p class="bold f28">您还在为这些漏水问题而烦恼吗？</p>
        <p class="mt20 f20">漏水烦恼 交给益鸟~</p>
      </div>
      <div class="sub flex_warp flex_items">
        <div
          class="pos fanxintunei"
          v-for="(item, index) in qiangs"
          :key="index"
        >
          <el-image :src="item.url" fit="cover" class="fanxintu"></el-image>
          <div
            class="abs f15"
            style="
              background: rgba(10, 9, 8, 0.4);
              bottom: 4px;
              height: 30px;
              color: white;
              line-height: 30px;
              width: 100%;
            "
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="t1 bgwhite flex_items flex_center flex_column">
      <div class="sub mt30 mb30">
        <p class="bold f28">渗漏水维修，找到漏点才是关键</p>
        <p class="mt20 f20">专业仪器 专业师傅找漏无忧</p>
      </div>
      <div class="sub flex_start flex_items">
        <el-image
          :src="imgUrl + 'product/fangshui/chanpin.png'"
          class="chanpin"
        ></el-image>
      </div>
    </div>
    <div class="flex_items flex_center pos">
      <el-image
        :src="imgUrl + 'product/fangshui/beijing1.png'"
        style="height: 260px; width: 100%; left: 0; top: 0"
        class="abs"
      ></el-image>
      <div class="flex_start flex_items sub abs" style="top: 44px">
        <div class="flex_items flex_column f15 zhao" style="color: white">
          <el-image
            :src="imgUrl + 'product/fangshui/icon1.png'"
            class="icon"
          ></el-image>
          <p class="mt10">精准找漏技术</p>
        </div>
        <div class="flex_items flex_column f15 zhao" style="color: white">
          <el-image
            :src="imgUrl + 'product/fangshui/icon2.png'"
            class="icon"
          ></el-image>
          <p class="mt10">局部找漏</p>
        </div>
        <div class="flex_items flex_column f15 zhao" style="color: white">
          <el-image
            :src="imgUrl + 'product/fangshui/icon3.png'"
            class="icon"
          ></el-image>
          <p class="mt10">局部拆除和修复</p>
        </div>
        <div class="flex_items flex_column f15 zhao" style="color: white">
          <el-image
            :src="imgUrl + 'product/fangshui/icon4.png'"
            class="icon"
          ></el-image>
          <p class="mt10">省心更省钱</p>
        </div>
      </div>
    </div>
    <!-- 施工现场 -->
    <div class="white flex_items flex_center">
      <div class="sub flex_column flex_items">
        <div
          class="chang flex_warp flex_items pos p30"
          style="top: 200px; background: white"
        >
          <div
            v-for="(item, index) in sg"
            :key="index"
            class="f18 bold flex_items flex_center"
          >
            <span v-if="item.tt != ''" style="width: 300px">{{ item.tt }}</span>
            <el-image
              v-if="item.url != ''"
              :src="item.url"
              style="width: 300px; height: 180px"
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div class="t4 p20" style="margin-top: 236px">
          <div class="sub flex_start flex_items" style="margin-top: 14px">
            <div class="flex_start flex_items">
              <div class="flex_column">
                <p class="bold f22 f-left">10秒获取房屋维修改造方案造价</p>
                <el-form
                  :inline="true"
                  :model="formInline"
                  class="demo-form-inline mr30 mt20"
                >
                  <el-form-item>
                    <el-input
                      v-model="formInline.user"
                      placeholder="姓名"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-input
                      v-model="formInline.phone"
                      placeholder="联系方式"
                    ></el-input>
                  </el-form-item>
                  <el-form-item v-if="wens.length != 0">
                    <el-select
                      v-model="formInline.region"
                      placeholder="房屋类目"
                      clearable
                    >
                      <el-option
                        v-for="(item, index) in wens"
                        :key="index"
                        :label="item.name"
                        :value="item.category_id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="onSubmit"
                      style="background: #007B46; border: none"
                      >立即申请</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div style="height: 90px; width: 1px; background: #a4764b"></div>
            </div>
            <div class="flex_column ml35">
              <p class="bold f34">免费咨询热线</p>
              <p class="bold mt10 f34" style="color: #a4764b">4000-987-009</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 黑科技 -->
    <!-- <div class="flex_center flex_items bgwhite">
      <div class="sub mt30 mb30">
        <p class="bold f28">益鸟防水黑科技，专利技术</p>
        <p class="mt20 f20">施工仅需8小时</p>
      </div>
    </div>
    <div class="flex_center flex_items">
      <div class="" style="width: 100%">
        <video-player
          ref="videoPlayer"
          class="video-player vjs-custom-skin mr15"
          :playsinline="true"
          :options="playerOptions"
          style="width: 100%"
        />
      </div>
    </div> -->
    <!-- 核心 -->
    <div class="tt3">
      <div class="flex_items flex_center pt20">
        <div class="sub mt40">
          <p class="bold f28">益鸟防水核心工艺，与建筑同寿命</p>
          <p class="mt20 f20">全拆也不仅仅是打开重做那么简单</p>
        </div>
      </div>
      <div class="op mt40 flex_center flex_items">
        <el-carousel :interval="5000" arrow="always" style="width: 1200px">
          <el-carousel-item v-for="(item, index) in banners" :key="index">
            <div class="flex_start flex_items">
              <div
                class="flex_column f-left plr20 mr20 bgwhite"
                style="width: 620px; height: 440px; border: 10px solid #d7ba7e"
              >
                <p class="bold f20 mt35">{{ item.data[0] }}</p>
                <p
                  class="f14 mt20"
                  v-for="(item2, index2) in item.data.slice(1)"
                  :key="index2"
                >
                  {{ item2 }}
                </p>
              </div>
              <el-image
                class=""
                style="width: 770px; height: 460px"
                :src="item.url"
                fit="cover"
              ></el-image>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 真实 -->
    <div class="flex_column">
      <div class="flex_center flex_items flex_column">
        <div class="sub f-center mt40">
          <p class="bold f28">真实施工案例，工地就在您身边</p>
        </div>
        <div class="flex_warp flex_items sub mt20">
          <div
            class="flex_column flex_items mr20 zz mt20"
            v-for="(item, index) in shigongs"
            :key="index"
            style="width: 380px"
          >
            <el-image :src="item.url" class="zhentu"></el-image>
            <div class="flex_between f15 mt5" style="width: 100%">
              <span>{{ item.t1 }}</span>
              <span>{{ item.t2 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fuw2k mt40 pt30 pos" style="color: white">
      <p class="bold f32 f-center mt20">物业推荐 客户好评如潮</p>
      <p class="mt10 f15 f-center">100000+ 房屋诊断分析数据，97.6%好评率</p>
      <div
        class="flex_column sub abs"
        style="left: 0; right: 0; bottom: 20px; margin: auto"
      ></div>
    </div>
  </div>
</template>
<script>
import { Toast } from "wot-design";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      wens: [],
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
      },
      qiangs: [
        {
          url: this.util.imgUrl + "product/fangshui/tu1.png",
          title: "卫生间渗漏水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu2.png",
          title: "厨房渗漏水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu3.png",
          title: "飘窗渗漏水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu4.png",
          title: "阳台雨棚渗漏水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu5.png",
          title: "空调外机渗漏水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu6.png",
          title: "屋顶外墙渗漏水",
        },
      ],
      sg: [
        { url: "", tt: "益鸟施工现场" },
        { url: this.util.imgUrl + "product/fangshui/fangshuitu1.png", tt: "" },
        { url: this.util.imgUrl + "product/fangshui/fangshuitu2.png", tt: "" },
        { url: this.util.imgUrl + "product/fangshui/fangshuitu3.png", tt: "" },
        { url: this.util.imgUrl + "product/fangshui/fangshuitu4.png", tt: "" },
        { url: this.util.imgUrl + "product/fangshui/fangshuitu5.png", tt: "" },
        { url: this.util.imgUrl + "product/fangshui/fangshuitu6.png", tt: "" },
        { url: "", tt: "专业防水涂刷" },
      ],
      shigongs: [
        {
          url: this.util.imgUrl + "product/fangshui/tu7.png",
          t1: "保利玫瑰花语",
          t2: "卫生间防水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu8.png",
          t1: "电建洺悦府",
          t2: "阳台防水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu9.png",
          t1: "新华小区",
          t2: "屋顶防水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu10.png",
          t1: "汇融悉尼湾",
          t2: "雨棚防水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu11.png",
          t1: "柳岸银州",
          t2: "飘窗防水",
        },
        {
          url: this.util.imgUrl + "product/fangshui/tu7.png",
          t1: "山水时光",
          t2: "墙面防水",
        },
      ],
      banners: [
        {
          url: this.util.imgUrl + "product/fangshui/ft1.jpg",
          data: [
            "瑞士西卡PK伪劣材料",
            "1.更牢固-粉料采用425水泥，强度更高，更抗裂",
            "2.更防水-特有桥接修复技术，细微裂缝自动修复",
            "3.更环保-法国A＋认证，无毒无味对人体无害",
            "4.都在用-北京大兴国际机场、纽约帝国大厦等",
          ],
        },
        {
          url: this.util.imgUrl + "product/fangshui/ft2.jpg",
          data: [
            "7大工艺PK偷工减料",
            "1.抹平厚度工艺标准,防止地面沉降导致开裂",
            "2.5层防水涂料确保防水效果",
            "3.排污落水管管口圆角处理",
            "4.墙角阴角堵漏宝处理",
            "5.十字涂刷法工艺标准，确保防水涂料不漏刷",
            "6.二次排水口，防止少量渗漏至回填层的水，通过二次排水口排出",
            "7.挡水处理工艺标准，防止侧面渗水导致墙角返潮发霉",
          ],
        },
        {
          url: this.util.imgUrl + "product/fangshui/fangshuitu.png",
          data: [
            "五遍防水PK两遍防水",
            "1.回填层拆除后回填底层涂刷两遍防水，确保意外漏水不会渗漏至楼下领居家",
            "2.基层防水施工前预做防水基层处理剂一遍，确保墙体缝隙均被渗透遮盖",
            "3.基层防水施工涂刷两遍",
          ],
        },
      ],
      // 视频
      videos: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "3:1", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "https://imgaes.yiniaoweb.com/gw/video/1.mp4", // url地址
          },
        ],
        hls: true,
        poster: "", // 你的封面地址
        width: "100%", // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //时间分割线
          durationDisplay: true, //总时间
          remainingTimeDisplay: false, //剩余播放时间
          progressControl: true, // 进度条
          fullscreenToggle: true, // 全屏按钮
        },
      },
    };
  },
  created() {
    this.getlei();
  },
  methods: {
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then((res) => {
        if (res.errcode == 200) {
          this.wens = res.data;
        }
      });
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 7,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
  },
};
</script>
>

<style>
.sub {
  max-width: 1200px;
}
.sub2 {
  max-width: 1300px;
}
.fanxintu {
  width: 393px;
  height: 256px;
}
.fanxintunei {
  margin-top: 5px;
  margin-right: 7px;
}
.fanxintunei:nth-child(3),
.fanxintu:nth-child(6) {
  margin-right: 0;
}
.fangshui .chanpin {
  width: 100%;
  height: 400px;
}
.fangshui .t3 {
  width: 100%;
  height: 260px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/fangshui/beijing1.png")
    no-repeat;
}
.fangshui .icon {
  width: 60px;
  height: 60px;
}
.fangshui .zhao {
  width: 200px;
}
.fangshui .chang {
  box-shadow: #ccc 0px 0px 10px;
}
.fangshui .t4 {
  width: 100%;
  height: 180px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/fangshui/beijing2.png")
    no-repeat;
}
.fangshui .tt3 {
  width: 100%;
  height: 860px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/fangshui/beijing3.png")
    no-repeat;
}
.zhentu {
  width: 100%;
}
.zz:nth-child(3),
.zz:nth-child(6) {
  margin-right: 0 !important;
}
.op .el-carousel__container {
  /* height: auto!important; */
}
</style>
