<template>
  <div class="casedetail">
    <!-- backtop -->
    <el-backtop :visibility-height="60">
      <i class="el-icon-caret-top" style="margin: auto"></i>
    </el-backtop>
    <Header></Header>
    <div v-loading="loading">
      <div class="">
        <el-image
          :src="imgUrl + 'home/kiss.png'"
          fit="contain"
          class="tt1"
        ></el-image>
      </div>
      <div class="ptb10 flex_center flex_items">
        <div class="flex_start" style="width: 1200px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/yicase' }"
              >益鸟案例</el-breadcrumb-item
            >
            <el-breadcrumb-item>益鸟案例详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="bgwhite flex_center flex_items pb20 bbe9s">
        <div class="subn flex_column flex_center">
          <div class="bbe9s pb20 flex_column flex_center flex_items pt20">
            <p class="bold f26">{{ model.title }}</p>
            <div
              class="mt10 f16 flex_between flex_items"
              style="width: 300px; color: rgb(143, 143, 143)"
            >
              <span>时间:{{ model.created_at }}</span>
              <span>浏览人数:{{ model.views }}</span>
            </div>
          </div>
          <div class="flex_around flex_items mt20">
            <div class="flex_column flex_items ads">
              <p class="bold f18">{{ model.serviceCategory.name }}</p>
              <p class="f14 mt10" style="color: #8f8f8f">项目类型</p>
            </div>
            <div class="flex_column flex_items ads">
              <p class="bold f18">{{ model.fit_up_style }}</p>
              <p class="f14 mt10" style="color: #8f8f8f">小区名称</p>
            </div>
            <div class="flex_column flex_items ads">
              <p class="bold f18">{{ model.area }}m²</p>
              <p class="f14 mt10" style="color: #8f8f8f">案例面积</p>
            </div>
          </div>
          <div class="flex_around flex_items mt20">
            <div class="flex_column flex_items ads">
              <p class="bold f18">{{ wsStore.name }}</p>
              <p class="f14 mt10" style="color: #8f8f8f">维修店铺</p>
            </div>
            <div class="flex_column flex_items ads">
              <p class="bold f18">业主好评</p>
              <el-rate
                v-model="model.scoring"
                disabled
                text-color="#ff9900"
                class="f14 mt10"
              >
              </el-rate>
            </div>
            <div class="flex_column flex_items ads">
              <p class="bold f18">店铺地址</p>
              <p class="f12 mt10" style="color: #8f8f8f">
                {{ wsStore.address }}
              </p>
            </div>
          </div>
          <div class="f-center f15 ptb10 mt30" style="background: #f5f5f5">
            业主说: {{ model.owner_evaluate }}
          </div>
        </div>
      </div>
      <div class="flex_center flex_items bgwhite pt30">
        <div class="subn flex_start flex_items pos">
          <div class="vueGallery pb20" style="margin-right: 50px">
            <div
              class="activePhoto"
              :style="'background-image: url(' + photos[activePhoto] + ');'"
            >
              <button
                type="button"
                aria-label="Previous Photo"
                class="previous"
                @click="previousPhoto()"
              >
                <i class="fas el-icon-arrow-left"></i>
              </button>
              <button
                type="button"
                aria-label="Next Photo"
                class="next"
                @click="nextPhoto()"
              >
                <i class="fas el-icon-arrow-right"></i>
              </button>
            </div>
            <div class="thumbnails flex_start mt10 bbe9s pb30 pt5">
              <div
                v-for="(photo, index) in photos.slice(0, 7)"
                :src="photo"
                :key="index"
                @click="changePhoto(index)"
                :class="[{ active: activePhoto == index }, 'mr5']"
                :style="'background-image: url(' + photo + ')'"
              ></div>
            </div>
            <!-- 案例介绍 -->
            <div class="flex_column pt20" v-if="model.images1">
              <div class="pos f-left">
                <span class="f20 bold">案例介绍</span>
                <div
                  style="
                    height: 8px;
                    width: 74px;
                    background: #d7bb7e;
                    bottom: 0px;
                    opacity: 0.8;
                  "
                  class="abs"
                ></div>
              </div>
              <!-- <el-image
                fit="fill"
                style="width: 100%"
                :key="index"
                :src="tuhose + model.images2[0].url"
                class="mt15"
              ></el-image>
              <el-image
                fit="fill"
                style="width: 100%"
                :key="index"
                :src="tuhose + model.images2[1].url"
                class="mt10"
              ></el-image>
              <el-image
                class="mt10"
                :src="tuhose + model.images1[0].url"
                style="width: 100%"
              ></el-image> -->
              <el-image
                v-for="(item, index) in model.images1"
                :key="index"
                class="mt10"
                :src="tuhose + item.url"
                style="width: 100%"
              ></el-image>
              <el-image
                v-for="(item, index) in model.images2"
                :key="index"
                class="mt10"
                :src="tuhose + item.url"
                style="width: 100%"
              ></el-image>
            </div>
          </div>
          <div>
            <div
              class="p10 bgwhite flex_column flex_items yuk abs"
              style="top: 100px"
            >
              <p class="f26 bold mt10">立即预约 极速上门检测</p>
              <p class="mt10 f12">
                已经有100000+位业主选择我们，并完成了房屋维修
              </p>
              <div class="flex_column" style="color: white; width: 300px">
                <el-input
                  placeholder="您的姓名"
                  prefix-icon="el-icon-user-solid"
                  v-model="formInline.user"
                  class="mt10 inin"
                >
                </el-input>
                <el-input
                  placeholder="手机号码"
                  prefix-icon="el-icon-phone"
                  v-model="formInline.phone"
                  class="mt10 inin"
                >
                </el-input>
                <el-button
                  @click="onSubmit"
                  type="warning"
                  class="mt10"
                  style="margin-top: 15px"
                  >立即预约</el-button
                >
                <div
                  class="p10 flex_start flex_items"
                  style="color: white; background: #007B46; margin-top: 40px"
                >
                  <img
                    class="mr5"
                    :src="imgUrl + 'product/jubu/dianhua.png'"
                    alt=""
                    style="width: 60px; height: 60px"
                  />
                  <div class="flex_column">
                    <p class="f14 f-left">客服咨询热线</p>
                    <p class="f22 bold">4000-987-009</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- moreanli -->
            <div
              class="bte9s pt20 abs flex_column"
              style="top: 500px; width: 311px"
            >
              <div class="flex_between">
                <span class="bold f18">案例推荐</span>
                <span
                  class="f16"
                  style="color: #8f8f8f; cursor: pointer"
                  @click="more"
                  >查看更多</span
                >
              </div>
              <div
                class="flex_start mt20 pos"
                v-for="(item, index) in processes"
                :key="index"
              >
                <!-- <el-image
                  :src="tuhose + item.images[0].url"
                  class=""
                  fit="fill"
                  style="width: 120px; height: 80px; margin-right: 50px"
                ></el-image> -->
                <img
                  :src="tuhose + item.images[0].url"
                  alt=""
                  style="width: 120px; height: 80px; margin-right: 50px"
                />
                <div class="flex_column f-left" style="width: 118px">
                  <p class="f14">{{ item.title }}</p>
                  <el-button
                    class="abs"
                    size="mini"
                    type="primary"
                    style="
                      right: 0;
                      bottom: 0;
                      background: #007B46;
                      border: none;
                    "
                    @click="chaxia(item.id)"
                    >查看详情</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import { Toast } from "wot-design";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      loading: true,
      model: {},
      wsStore: {},
      processes: {},
      value: 5,
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
      },
      activePhoto: 0,
      photos: [],
    };
  },
  created() {
    this.$store.commit("setpage", "益鸟案例");
    this.getData(this.$route.query.id);
  },
  methods: {
    getData(id) {
      this.util
        .get(this.HOST + "/api/exampleInfo", {
          id: id,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.model = res.data.model;
            this.wsStore = res.data.model.wsStore;
            this.processes = res.data.processes;
            for (let i = 0; i < res.data.model.images.length; i++) {
              this.photos.push(this.util.tuhose + res.data.model.images[i].url);
            }
            this.loading = false;
          }
        });
    },
    chaxia(id) {
      this.photos = [];
      this.getData(id);
    },
    more() {
      this.$router.push({
        path: "/yicase",
        query: {},
      });
    },
    changePhoto(index) {
      this.activePhoto = index;
    },
    nextPhoto() {
      this.changePhoto(
        this.activePhoto + 1 < this.photos.length ? this.activePhoto + 1 : 0
      );
    },
    previousPhoto() {
      this.changePhoto(
        this.activePhoto - 1 >= 0
          ? this.activePhoto - 1
          : this.photos.length - 1
      );
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 2,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
  },
  components: {
    Header: header,
    Footer: footer,
  },
};
</script>
<style>
.casedetail .tt1 {
  width: 100%;
}
.subn {
  width: 1200px;
}
.ads {
  width: 150px;
  text-align: center;
}
/* lunb */
.vueGallery {
  width: 800px;
}
.vueGallery .activePhoto {
  width: 100%;
  height: 450px;
  /* margin-bottom: 5px; */
  /* padding-bottom: 65%; */
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  border: 2px solid #fff;
  position: relative;
}
.vueGallery .activePhoto button {
  border: none;
  background-color: transparent;
  font-size: 32px;
  color: #fff;
  opacity: 0.5;
  position: absolute;
  outline: none;
  height: 100%;
}
.vueGallery .activePhoto button:hover {
  opacity: 1;
  cursor: pointer;
}
.vueGallery .activePhoto button.previous {
  padding: 0 1em 0 0.7em;
  left: 0;
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=1 );
}
.vueGallery .activePhoto button.next {
  padding: 0 0.7em 0 1em;
  right: 0;
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=1 );
}
.vueGallery .thumbnails {
  /* display: grid; */
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 5px;
  width: 804px;
}
.vueGallery .thumbnails div {
  /* width: 100%; */
  width: 107px;
  border: 2px solid #fff;
  outline: 2px solid #fff;
  cursor: pointer;
  /* padding-bottom: 65%; */
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
}
.vueGallery .thumbnails div:last-child {
  margin-right: 0;
}
.vueGallery .thumbnails div:hover {
  opacity: 0.6;
}
.vueGallery .thumbnails div.active {
  outline: 3px solid #007b46;
  z-index: 10;
}
</style>
