<template>
  <div class="yicase">
    <!-- backtop -->
    <el-backtop :visibility-height="60">
      <i class="el-icon-caret-top" style="margin: auto"></i>
    </el-backtop>
    <Header></Header>
    <div class="main" v-loading="loading">
      <div class="">
        <el-image
          :src="imgUrl + 'home/nb.png'"
          fit="cover"
          class="tt1"
        ></el-image>
      </div>
      <!-- 搜索 -->
      <div class="ptb10 flex_center flex_items">
        <div class="flex_start" style="width: 1200px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>益鸟案例</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="flex_center flex_items bgwhite">
        <div class="flex_column f13" style="width: 1200px">
          <div class="flex_start flex_items ptb10 bbe9s">
            <div class="ptb5 mr10 f-left ss">项目类型</div>
            <div
              v-for="(item, index) in ser1"
              :key="index"
              :class="[
                'ptb5',
                'mr10',
                'ss',
                index == choseone ? 'chose1' : 'no1',
              ]"
              @click="chose1(index, item)"
            >
              {{ item.name }}
            </div>
          </div>
          <!-- 面积 -->
          <div class="flex_start flex_items ptb10 bbe9s">
            <div class="ptb5 mr10 f-left ss">面积</div>
            <div
              v-for="(item, index) in ser2"
              :key="index"
              :class="[
                'ptb5',
                'mr10',
                'ss',
                index == chosetwo ? 'chose1' : 'no1',
              ]"
              @click="chose2(index, item)"
            >
              {{ item.name }}
            </div>
          </div>
          <!-- 价格范围 -->
          <!-- <div class="flex_start flex_items ptb10 bbe9s">
            <div class="ptb5 mr10 f-left ss">价格范围</div>
            <div
              v-for="(item, index) in ser3"
              :key="index"
              :class="[
                'ptb5',
                'mr10',
                'ss',
                index == chosethree ? 'chose1' : 'no1',
              ]"
              @click="chose3(index, item)"
            >
              {{ item.name }}
            </div>
          </div> -->
          <!-- 施工店铺 -->
          <div class="flex_warp flex_items ptb10 bbe9s">
            <div class="ptb5 mr10 f-left ss">施工店铺</div>
            <div
              v-for="(item, index) in ser4"
              :key="index"
              style="width: 78px"
              :class="[
                'ptb5',
                'mr10',
                'ss',
                'mt10',
                index == chosefour ? 'chose1' : 'no1',
              ]"
              @click="chose4(index, item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="flex_start flex_items ptb10 bbe9s">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
            >
              <el-form-item label="">
                <el-input
                  v-model="formInline.user"
                  placeholder="输入小区名称"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- list -->
      <div class="flex_center flex_items mt20">
        <div style="width: 1215px" v-loading="loading2">
          <div v-if="list.length != 0" class="flex_warp flex_items">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="flex_column mr5 mt10 bbe9s p10 bgwhite"
              @click="casedetail(item.id)"
              style="cursor: pointer"
            >
              <el-image
                :src="tuhose + item.url"
                fit="cover"
                class="tuhose"
              ></el-image>
              <div class="flex_between flex_items mt10">
                <span class="bold f18">{{ item.title }}</span>
                <div class="flex_start flex_items">
                  <el-image
                    class="mr5"
                    :src="imgUrl + 'home/chakan.png'"
                    style="width: 20px; height: 15px"
                  ></el-image>
                  <span class="f14 cc">{{ item.views }}</span>
                </div>
              </div>
              <p class="cc f12 mt10 f-left">
                {{ item.name }}&nbsp;|&nbsp;{{ item.area }}m²
              </p>
            </div>
          </div>
          <div v-else class="flex_center flex_items">
            <span class="f18">--暂无搜索结果--</span>
          </div>
        </div>
      </div>
    </div>
    <Footer class="mt60"></Footer>
  </div>
</template>

<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      loading: true,
      loading2: false,
      // 项目类型
      ser1: [],
      choseone: 0,
      choseoneid: 0,
      // 面积
      ser2: [],
      chosetwo: 0,
      chosetwoid: 0,
      // 价格范围
      ser3: [],
      chosethree: 0,
      chosethreeid: 0,
      // 店铺
      ser4: [],
      chosefour: 0,
      chosefourid: 0,
      list: [],
      formInline: {
        user: "",
      },
    };
  },
  created() {
    this.$store.commit("setpage", "益鸟案例");
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/api/example/", {
          service_category_id: this.choseoneid,
          price_category_area: this.chosetwoid,
          price_category_id: this.chosethreeid,
          store_id: this.chosefourid,
          keywords: this.formInline.user,
        })
        .then((res) => {
          if (res.errcode == 200) {
            res.data.stores.unshift({
              name: "不限",
              store_id: 0,
            });
            res.data.service_category_arr.unshift({
              name: "不限",
              service_category_id: 0,
            });
            res.data.price_category_area.unshift({
              name: "不限",
              price_category_area: 0,
            });
            res.data.price_category_arr.unshift({
              name: "不限",
              price_category_id: 0,
            });
            this.ser1 = res.data.service_category_arr;
            this.ser2 = res.data.price_category_area;
            this.ser3 = res.data.price_category_arr;
            this.ser4 = res.data.stores;
            this.list = res.data.data;
            this.loading = false;
            this.loading2 = false;
          }
        });
    },
    chose1(idx, item) {
      this.loading2 = true;
      this.choseone = idx;
      this.choseoneid = item.category_id;
      this.getData();
    },
    chose2(idx, item) {
      this.loading2 = true;
      this.chosetwo = idx;
      this.chosetwoid = item.category_id;
      this.getData();
    },
    chose3(idx, item) {
      this.loading2 = true;
      this.chosethree = idx;
      this.chosethreeid = item.category_id;
      this.getData();
    },
    chose4(idx, item) {
      this.loading2 = true;
      this.chosefour = idx;
      this.chosefourid = item.store_id;
      this.getData();
    },
    onSubmit() {
      this.getData();
    },
    casedetail(idx) {
      this.$router.push({
        path: "/casedetail",
        query: {
          id: idx,
        },
      });
    },
  },
  components: {
    Header: header,
    Footer: footer,
  },
};
</script>
<style>
.yicase .tt1 {
  width: 100%;
  height: 700px;
}
.yicase .ss {
  width: 75px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
}
.no1 {
  color: #000;
  background: none;
}
.chose1 {
  color: white;
  background: #007b46;
}
.tuhose {
  width: 380px;
  height: 300px;
}
.yicase .el-button--primary {
  border: none;
  background-color: #d7ba7e !important;
}
</style>
