<template>
  <!-- fanxin -->
  <div class="fanxin bgwhite" style="padding-top: 106px">
    <div class="t1 bgwhite flex_items flex_center flex_column">
      <div class="sub mt30 mb30">
        <p class="bold f28">您还在为这些墙面问题而烦恼吗？</p>
        <p class="mt20 f20">墙面问题难道一定要铲了重新来吗？</p>
      </div>
      <div class="sub flex_warp flex_items">
        <div
          class="pos fanxintunei"
          v-for="(item, index) in qiangs"
          :key="index"
        >
          <el-image :src="item.url" fit="cover" class="fanxintu"></el-image>
          <div
            class="abs f15"
            style="
              background: rgba(10, 9, 8, 0.4);
              bottom: 4px;
              height: 30px;
              color: white;
              line-height: 30px;
              width: 100%;
            "
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <!-- t2 -->
    <div class="t2 mt20">
      <div class="flex_items flex_center">
        <div class="sub mt30 mb30">
          <p class="bold f28">您知道吗？</p>
          <p class="mt20 f20">墙面问题不仅破坏美观，还影响您的健康!</p>
        </div>
      </div>
      <el-image
        :src="imgUrl + 'product/fanxin/tt.png'"
        class="tt mt20"
      ></el-image>
    </div>
    <div class="flex_items flex_center">
      <div class="sub mt30 mb30">
        <p class="bold f28">解决墙面问题,益鸟不仅有乳胶漆</p>
        <p class="mt20 f20">多重方案，多种选择，一切只为更好的为您服务</p>
      </div>
    </div>
    <!-- <div>
      <el-image
        fit="cover"
        :src="imgUrl + 'product/fanxin/huhu.jpg'"
        class="huqiang"
      ></el-image>
    </div>
    <div>
      <el-image
        fit="cover"
        :src="imgUrl + 'product/fanxin/huqiangban1.jpg'"
        class="huqiang"
      ></el-image>
    </div>
    <div style="height: 900px">
      <el-image
        fit="cover"
        :src="imgUrl + 'product/fanxin/guizaoni.png'"
        class="huqiang"
      ></el-image>
    </div>
    <div>
      <el-image
        fit="cover"
        :src="imgUrl + 'product/fanxin/qiangbu.png'"
        class="huqiang"
      ></el-image>
    </div> -->
    <!--  -->
    <div class="flex_center flex_items huoqu">
      <div class="sub flex_start flex_items">
        <div class="flex_start flex_items mt20">
          <div class="flex_column">
            <p class="bold f22 f-left">10秒获取房屋维修改造方案造价</p>
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline mt20 mr30"
            >
              <el-form-item>
                <el-input
                  v-model="formInline.user"
                  placeholder="姓名"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="formInline.phone"
                  placeholder="联系方式"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-if="wens.length != 0"
                  v-model="formInline.region"
                  placeholder="房屋类目"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in wens"
                    :key="index"
                    :label="item.name"
                    :value="item.category_id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="onSubmit"
                  style="background: #007B46; border: none"
                  >立即申请</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div style="height: 90px; width: 1px; background: #a4764b"></div>
        </div>
        <div class="flex_column ml35">
          <p class="bold f34">免费咨询热线</p>
          <p class="bold mt20 f34" style="color: #a4764b">4000-987-009</p>
        </div>
      </div>
    </div>
    <!-- 专业诊断 -->
    <div class="bgwhite">
      <div class="flex_items flex_center">
        <div class="sub mt30 mb30">
          <p class="bold f28">专业上门诊断</p>
          <p class="mt20 f20">匹配最佳方案</p>
        </div>
      </div>
      <div class="flex_center flex_items">
        <div class="sub">
          <img :src="imgUrl + 'home/fuw.png'" alt="" class="fufu" />
        </div>
      </div>
    </div>
    <!-- 省心 -->
    <div class="sheng" style="color: white">
      <div class="flex_item flex_center">
        <div class="sub mb30" style="padding-top: 100px">
          <p class="bold f32 mt20 f-center">省心给你，麻烦给我</p>
          <p class="mt20 f20 f-center">透明施工管控，真实看得见</p>
        </div>
      </div>
      <div class="flex_item flex_center">
        <div class="sub flex_start flex_items">
          <div class="flex_column">
            <div class="flex_start">
              <img
                :src="imgUrl + 'product/jubu/weixin1.png'"
                alt=""
                class="weiwei mr20"
              />
              <img
                :src="imgUrl + 'product/jubu/weixin.png'"
                alt=""
                class="weiwei mr20"
              />
            </div>
            <div class="flex_column mt20">
              <p class="bold f24 f-left">微信群管理</p>
              <p class="mt10 f15 f-left">
                自合同签订开始建立微信群，每天在群里播报施工进度和使用材料，让客户<br />不用到施工现场也能全面了解施工过程和所用正品材料
              </p>
            </div>
          </div>
          <div class="flex_column" style="margin-left: 100px">
            <img
              :src="imgUrl + 'product/jubu/yonghuduan.png'"
              alt=""
              class="weiwei"
            />
            <div class="flex_column">
              <p class="bold f20 f-left mt20">用户端小程序查看施工流程</p>
              <p class="mt10 f15 f-left">
                用户可以通过益鸟美居用户小程序，查看施工进度，保留施工现场的所有施工素材，更透明更可靠，让质保有意义
              </p>
            </div>
          </div>
          <div class="flex_column" style="margin-left: 50px">
            <img
              :src="imgUrl + 'product/jubu/gongrenapp.png'"
              alt=""
              class="weiwei"
            />
            <div class="flex_column mt20">
              <p class="bold f24 f-left">工人APP上传施工数据</p>
              <p class="mt10 f15 f-left">
                产业工人严格按照施工流程进行施工安排和施工图片与视频的上传，透明可靠
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 真实案例 -->
    <div class="flex_item flex_center bgwhite">
      <div class="sub mt30 mb30">
        <p class="bold f32 mt20 f-center">真实施工案例，工地就在您身边</p>
      </div>
    </div>
    <div class="op">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in banners" :key="index">
          <div class="flex_start flex_items">
            <div>
              <el-image
                class="banner"
                :src="item.url1"
                fit="cover"
                style="width: 670px"
              ></el-image>
            </div>
            <div class="pos">
              <el-image class="banner" :src="item.url2" fit="cover"></el-image>
              <div
                class="abs flex_column flex-items"
                style="
                  width: 200px;
                  height: 100px;
                  color: white;
                  bottom: 10%;
                  left: 0;
                  background: rgba(5, 113, 61, 0.5);
                "
              >
                <span class="bold f20 mt20">/墙面翻新/</span>
                <span class="f15 mt5">施工工地: 金科博翠府</span>
              </div>
            </div>
          </div>
          <!-- <el-image class="banner" :src="item.url" fit="cover"></el-image> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="fuw2k mt40 pt30 pos" style="color: white">
      <p class="bold f32 f-center mt20">业主口碑看得见</p>
      <p class="mt10 f15 f-center">100000+ 房屋诊断分析数据，97.6%好评率</p>
      <div
        class="flex_column sub abs"
        style="left: 0; right: 0; bottom: 20px; margin: auto"
      ></div>
    </div>
  </div>
</template>
<script>
import { Toast } from "wot-design";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
      },
      wens: [],
      banners: [
        {
          url1: this.util.imgUrl + "product/fanxin/fanxinqian.png",
          url2: this.util.imgUrl + "product/fanxin/fanxinhou.png",
        },
      ],
      qiangs: [
        {
          url: this.util.imgUrl + "product/fanxin/tu1.png",
          title: "天花板表皮脱落",
        },
        {
          url: this.util.imgUrl + "product/fanxin/tu2.png",
          title: "背水面墙面掉落",
        },
        {
          url: this.util.imgUrl + "product/fanxin/tu3.png",
          title: "墙面开裂",
        },
        {
          url: this.util.imgUrl + "product/fanxin/tu4.png",
          title: "墙面污损",
        },
        {
          url: this.util.imgUrl + "product/fanxin/tu5.png",
          title: "墙面粉化",
        },
        {
          url: this.util.imgUrl + "product/fanxin/tu6.png",
          title: "墙面发霉",
        },
      ],
    };
  },
  created() {
    this.$store.commit("setpage", "产品中心");
    this.getlei();
  },
  methods: {
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then((res) => {
        if (res.errcode == 200) {
          this.wens = res.data;
        }
      });
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 7,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
  },
};
</script>

<style>
.sub {
  max-width: 1200px;
}
.sub2 {
  max-width: 1300px;
}
.fanxintu {
  width: 393px;
  height: 256px;
}
.fanxintunei {
  margin-top: 5px;
  margin-right: 7px;
}
.fanxintunei:nth-child(3),
.fanxintu:nth-child(6) {
  margin-right: 0;
}
.fanxin .t2 {
  width: 100%;
  height: 900px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/fanxin/beijing1.png")
    no-repeat;
}
.fanxin .huoqu {
  width: 100%;
  height: 200px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/fanxin/beijing2.png")
    no-repeat;
}
.huqiang {
  width: 100%;
  height: 900px;
}
.fufu {
  width: 100%;
  height: 550px;
}
.weiwei {
  width: 255px;
  height: 502px;
}
.sheng {
  width: 100%;
  height: 1000px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/jubu/beijing6.png")
    no-repeat;
}
.banner {
  width: 100%;
  height: 600px;
}
.op .el-carousel__container {
  height: 600px !important;
}
.fuw2k {
  width: 100%;
  height: 838px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/home/fuw2.png");
}
</style>
