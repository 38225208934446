<template>
  <div class="product">
    <!-- backtop -->
    <el-backtop :visibility-height="60">
      <i class="el-icon-caret-top" style="margin: auto"></i>
    </el-backtop>
    <Header />
    <div class="">
      <el-carousel v-if="kind == 0" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in l1" :key="index">
          <el-image class="banner" :src="item" fit="cover"></el-image>
        </el-carousel-item>
      </el-carousel>
      <el-carousel v-if="kind == 1" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in l2" :key="index">
          <el-image class="banner" :src="item" fit="cover"></el-image>
        </el-carousel-item>
      </el-carousel>
      <el-carousel v-if="kind == 2" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in l3" :key="index">
          <el-image class="banner" :src="item" fit="cover"></el-image>
        </el-carousel-item>
      </el-carousel>
      <el-carousel v-if="kind == 3" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in l4" :key="index">
          <el-image class="banner" :src="item" fit="cover"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div :class="[kind == 0 ? 't2' : '', 'pos', 'flex_center', 'flex_items']">
      <div
        class="t2nav flex_center flex_items abs"
        style="top: -33px; z-index: 10"
      >
        <div class="flex_around flex_items p10 t2item">
          <div
            class="navitem flex_column flex_items"
            style="visibility: hidden"
          >
            12
          </div>
          <div
            class="navitem flex_column flex_items pos"
            @click="hosekind('0')"
          >
            <div class="abs nabline"></div>
            <img
              v-if="kind == 0"
              :src="imgUrl + 'product/jubu/jugai.png'"
              alt=""
              class="jugai"
            />
            <img
              v-else
              :src="imgUrl + 'product/jubu/jugai1.png'"
              alt=""
              class="jugai"
            />
            <p :class="['mt10', 'f14', kind == 0 ? 'green' : '']">局部改造</p>
          </div>
          <div
            class="navitem flex_column flex_items pos"
            @click="hosekind('1')"
          >
            <div class="abs nabline"></div>
            <img
              v-if="kind == 1"
              :src="imgUrl + 'product/jubu/qiangmian.png'"
              alt=""
              class="jugai"
            />
            <img
              v-else
              :src="imgUrl + 'product/jubu/qiangmian1.png'"
              alt=""
              class="jugai"
            />
            <p :class="['mt10', 'f14', kind == 1 ? 'green' : '']">墙面翻新</p>
          </div>
          <div
            class="navitem flex_column flex_items pos"
            @click="hosekind('2')"
          >
            <div class="abs nabline"></div>
            <img
              v-if="kind == 2"
              :src="imgUrl + 'product/jubu/fangshui.png'"
              alt=""
              class="jugai"
            />
            <img
              v-else
              :src="imgUrl + 'product/jubu/fangshui1.png'"
              alt=""
              class="jugai"
            />
            <p :class="['mt10', 'f14', kind == 2 ? 'green' : '']">防水补漏</p>
          </div>
          <div
            class="navitem flex_column flex_items pos"
            @click="hosekind('3')"
          >
            <div class="abs nabline"></div>
            <img
              v-if="kind == 3"
              :src="imgUrl + 'product/jubu/weixiu.png'"
              alt=""
              class="jugai"
            />
            <img
              v-else
              :src="imgUrl + 'product/jubu/weixiu1.png'"
              alt=""
              class="jugai"
            />
            <p :class="['mt10', 'f14', kind == 3 ? 'green' : '']">维修安装</p>
            <div class="abs nabline2"></div>
          </div>
          <div
            class="navitem flex_column flex_items"
            style="visibility: hidden"
          >
            12
          </div>
        </div>
      </div>
      <div v-if="kind == 0" class="mt30 flex_items flex_center">
        <div class="flex_column sub">
          <p class="bold f22 f-left">10秒获取房屋维修改造方案造价</p>
          <div class="flex_center">
            <div class="flex_start flex_items mt20">
              <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
              >
                <el-form-item>
                  <el-input
                    v-model="formInline.user"
                    placeholder="姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input
                    v-model="formInline.phone"
                    placeholder="联系方式"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-select
                    v-if="wens.length != 0"
                    v-model="formInline.region"
                    placeholder="房屋类目"
                  >
                    <el-option
                      v-for="(item, index) in wens"
                      :key="index"
                      :label="item.name"
                      :value="item.category_id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="onSubmit"
                    style="background: #007B46; border: none"
                    >立即申请</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- jubu -->
    <Jubu v-if="kind == 0"></Jubu>
    <fanxin v-if="kind == 1"></fanxin>
    <fangshui v-if="kind == 2"></fangshui>
    <weixiu v-if="kind == 3"></weixiu>
    <Footer class="mt100"></Footer>
  </div>
</template>

<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import jubu from "@/components/jubu.vue";
import fanxin from "@/components/fanxin.vue";
import fangshui from "@/components/fangshui.vue";
import weixiu from "@/components/weixiu.vue";
import { Toast } from "wot-design";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      kind: 0,
      wens: [],
      l1: [
        this.util.imgUrl + "product/jubu/b1.png",
        this.util.imgUrl + "product/jubu/bb98.jpg",
      ],
      l2: [this.util.imgUrl + "product/fanxin/b1.png"],
      l3: [this.util.imgUrl + "product/fangshui/b1.jpg"],
      l4: [this.util.imgUrl + "product/weixiu/b1.png"],
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
      },
    };
  },
  created() {
    if (this.$route.query.kind) {
      this.kind = this.$route.query.kind;
    } else {
      this.kind = 0;
    }
    this.$store.commit("setpage", "产品中心");
    this.getlei();
  },
  methods: {
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then((res) => {
        if (res.errcode == 200) {
          this.wens = res.data;
        }
      });
    },
    hosekind(item) {
      this.kind = item;
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 7,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
  },
  components: {
    Header: header,
    Jubu: jubu,
    fanxin: fanxin,
    Footer: footer,
    fangshui: fangshui,
    weixiu: weixiu,
  },
};
</script>
<style>
.banner1 {
  width: 100%;
  height: 700px;
  background: url(https://imgaes.yiniaoweb.com/gw/product/jubu/banner1.png)
    no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
}
.t2 {
  width: 100%;
  height: 320px;
  background: url(https://imgaes.yiniaoweb.com/gw/product/jubu/beijing1.png)
    no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
}
.t2nav {
  max-width: 1100px;
}
.navitem {
  width: 33%;
  cursor: pointer;
}
.jugai {
  width: 80px;
  height: 60px;
}
.t2item {
  width: 1200px;
  background: white;
  padding: 20px 0 20px 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 2px 2px 2px #eeeeee;
}
.nabline {
  width: 1px;
  height: 50px;
  background: #e9e9e9;
  left: 0;
  top: 20px;
}
.nabline2 {
  width: 1px;
  height: 50px;
  background: #e9e9e9;
  right: 0;
  top: 20px;
}
.green {
  color: #04723d;
}
.product .el-carousel__container {
  height: 700px !important;
}
.product .banner {
  height: 700px !important;
}
.product i {
  margin: 5px !important;
}
</style>
