<template>
  <div class="weixiu bgwhite" style="padding-top: 106px">
    <div class="bgwhite flex_items flex_center flex_column pb20">
      <div class="sub mt30 mb30">
        <p class="bold f28">这些居家问题怎么办？</p>
      </div>
      <div class="sub flex_warp flex_items">
        <div
          class="pos fanxintunei"
          v-for="(item, index) in wentis"
          :key="index"
        >
          <el-image :src="item.url" fit="cover" class="fanxintu"></el-image>
          <div
            class="abs f15"
            style="
              background: rgba(10, 9, 8, 0.4);
              bottom: 4px;
              height: 30px;
              color: white;
              line-height: 30px;
              width: 100%;
            "
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <!-- fuwu -->
    <!-- <div class="t1">
      <div class="flex_items flex_center flex_column pt20">
        <div class="sub mt30 mb30">
          <p class="bold f28">服务优势</p>
        </div>
      </div>
      <el-image
        :src="imgUrl + 'product/weixiu/vs.png'"
        fit="contain"
        style="width: 100%; height: 430px"
      ></el-image>
    </div> -->
    <!-- mendian -->
    <div class="mendian">
      <div class="flex_items flex_center flex_column">
        <!-- <div class="sub mt30 mb30">
         
        </div> -->
      </div>
      <el-image
        :src="imgUrl + 'product/weixiu/shequmendian.png'"
        fit="contain"
        style="width: 100%; height: 1000px"
        mode="aspectFill"
      ></el-image>
    </div>
    <!-- 自有施工 -->
    <div class="pb35">
      <div class="flex_center flex_items flex_column">
        <div class="sub mt100 mb30">
          <p class="bold f28">自有施工工人:严格认证 系统培训</p>
        </div>
        <div class="flex_start flex_items" style="width:1200px">
          <div class="flex_column flex_items mr10">
            <div
              class="flex_center flex_items flex_column"
              style="background: #c9a261; width: 100%; height: 100px"
            >
              <p class="bold f18">自有工人</p>
              <p class="f14">全职师傅，服务保障</p>
            </div>
            <el-image
              :src="imgUrl + 'product/weixiu/ziyougongren.png'"
              style="height: 500px;width:322px"
            ></el-image>
          </div>
          <div class="flex_column flex_items mr10" style="margin-top: 100px">
            <el-image
              :src="imgUrl + 'product/weixiu/jingtiaoxixuan.png'"
              style="height: 500px;width:322px"
            ></el-image>
            <div
              class="flex_center flex_items flex_column"
              style="background: #c9a261; width: 100%; height: 100px"
            >
              <p class="bold f18">精挑细选</p>
              <p class="f14">5年以上，五星级成长体系</p>
            </div>
          </div>
          <div class="flex_column flex_items mr10">
            <div
              class="flex_center flex_items flex_column"
              style="background: #c9a261; width: 100%; height: 100px"
            >
              <p class="bold f18">认证培训</p>
              <p class="f14">经过38门培训考核</p>
            </div>
            <el-image
              :src="imgUrl + 'product/weixiu/peixun.png'"
              style="height: 500px;width:322px"
            ></el-image>
          </div>
          <div class="flex_column flex_items mr10" style="margin-top: 100px">
            <el-image
              :src="imgUrl + 'product/weixiu/baozhengfuwu.png'"
              style="height: 500px;width:322px"
            ></el-image>
            <div
              class="flex_center flex_items flex_column"
              style="background: #c9a261; width: 100%; height: 100px"
            >
              <p class="bold f18">保证服务</p>
              <p class="f14">质量更可靠，服务有保证</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 真实 -->
    <div class="flex_column bgwhite pb30">
      <div class="flex_center flex_items flex_column">
        <div class="sub f-center mt40">
          <p class="bold f28">真实施工案例，工地就在您身边</p>
        </div>
        <div class="flex_warp flex_items sub mt20">
          <div
            class="flex_column flex_items mr20 zz mt20"
            v-for="(item, index) in shigongs"
            :key="index"
            style="width: 386px"
          >
            <el-image :src="item.url" class="zhentu"></el-image>
            <div class="flex_between f14 mt5" style="width: 100%">
              <span>{{ item.t1 }}</span>
              <span>{{ item.t2 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fuw2k mt40 pt30 pos" style="color: white">
      <p class="bold f32 f-center mt20">物业推荐 客户好评如潮</p>
      <p class="mt10 f15 f-center">100000+ 房屋诊断分析数据，97.6%好评率</p>
      <div
        class="flex_column sub abs"
        style="left: 0; right: 0; bottom: 20px; margin: auto"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      wentis: [
        {
          url: this.util.imgUrl + "product/weixiu/tu1.png",
          title: "瓷砖空鼓脱落",
        },
        {
          url: this.util.imgUrl + "product/weixiu/tu2.png",
          title: "阳台吊顶维修",
        },
        {
          url: this.util.imgUrl + "product/weixiu/tu3.png",
          title: "冷热水管漏水",
        },
        {
          url: this.util.imgUrl + "product/weixiu/tu4.png",
          title: "落水管渗漏水",
        },
        {
          url: this.util.imgUrl + "product/weixiu/tu5.png",
          title: "家电安装",
        },
        {
          url: this.util.imgUrl + "product/weixiu/tu6.png",
          title: "电路维修",
        },
      ],
      shigongs: [
        {
          url: this.util.imgUrl + "product/weixiu/anli1.png",
          t1: "保利玫瑰花语",
          t2: "家电安装",
        },
        {
          url: this.util.imgUrl + "product/weixiu/anli2.png",
          t1: "电建洺悦府",
          t2: "电路维修",
        },
        {
          url: this.util.imgUrl + "product/weixiu/anli3.png",
          t1: "新华小区",
          t2: "水龙头换新",
        },
        {
          url: this.util.imgUrl + "product/weixiu/anli4.png",
          t1: "汇融悉尼湾",
          t2: "洁具维修",
        },
        {
          url: this.util.imgUrl + "product/weixiu/anli5.png",
          t1: "柳岸银州",
          t2: "灯具安装",
        },
        {
          url: this.util.imgUrl + "product/weixiu/anli6.png",
          t1: "山水时光",
          t2: "五金安装",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>
<style>
.weixiu .t1 {
  width: 100%;
  height: 650px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/product/weixiu/beijing1.png")
    no-repeat;
}
.weixiu .mendian {
  width: 100%;
  height: 900px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  /* background: url("https://imgaes.yiniaoweb.com/gw/product/weixiu/shequmendian.png")
    no-repeat; */
  color: white;
}
</style>
