<template>
  <div class="aboutus">
    <el-backtop :visibility-height="60">
      <i class="el-icon-caret-top" style="margin: auto"></i>
    </el-backtop>
    <Header></Header>
    <div>
      <el-image
        :src="imgUrl + 'about/banner123.png'"
        fit="cover"
        class="tt1"
      ></el-image>
      <div>
        <div class="bgwhite flex_items flex_center flex_column">
          <div class="mt30 mb30" style="width: 1200px">
            <p class="bold f32">公司介绍</p>
          </div>
          <div class="flex_start flex_items">
            <div
              class="f15 flex_column pos"
              style="
                background: #06332e;
                color: white;
                width: 50%;
                height: 350px;
              "
            >
              <div class="f-left" style="width: 65%; margin: 30px 0 0 150px">
                <p>{{ fan }}</p>
                <p class="f22">{{ title }}</p>
                <p class="mt30">
                  {{
                    wen
                  }}来益鸟团队通过行业唯一"自有店铺、自有师傅"体系,接触了40000家以上的客户,入户诊断超过10万+客户，好评率高达:97.6%。<br />益鸟开创行业先河，自建益鸟智能维护系统,未来将基于大数据、人工智能和物联网等技术将行业效率提高40%，更好地保证客户体验，力求获得每一位客户的信赖。
                </p>
              </div>
              <el-image
                :src="imgUrl + 'about/lianjie.png'"
                class=""
                style="width: 50px; height: 23px; top: -30%; right: -97%"
              ></el-image>
            </div>
            <div
              class="f15 flex_column"
              style="background: #f5f5f5; width: 50%; height: 350px"
            >
              <div class="f-left" style="width: 65%; margin: 30px 0 0 100px">
                <p>YI NIAO CHU XIN</p>
                <p class="f22">益鸟初心</p>
                <p class="mt30">
                  <span class="bold">益鸟CEO说:</span
                  >益鸟成立之前，家装后的房屋维修市场非常混乱，业主的维修需求难以满足，找不到相应的人来做专业的事。<br />我们一直做大型工程项目的维修改造，拥有先进专业的技术。当时，就萌发了这么一个想法，我们来承担起房屋维修行业的变革使命，成为行业的变革者，制定行业规范，打造行业标杆，树立行业标准，益鸟因此而生。
                </p>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="" v-if="ss == 1">
            <img :src="imgUrl + 'home/xpp.jpg'" alt="" style="width: 100%" />
            <!-- <el-image :src="imgUrl+'home/xpp.png'" fit="cover" style="width:100%"></el-image> -->
            <!-- <div class="flex_center flex_items">
              <div class="mt30 mb30" style="width: 1200px">
              </div>
            </div> -->
          </div>
          <!-- 核心 -->
          <div class="bgwhite flex_column">
            <div class="flex_center flex_items">
              <div class="mt30 mb30" style="width: 1200px">
                <p class="bold f28 mt20">核心服务优势</p>
              </div>
            </div>
            <div class="flex_center flex_items">
              <div style="width: 1000px" class="flex_column">
                <div class="flex_start flex_items">
                  <el-image
                    :src="imgUrl + 'about/tu1.png'"
                    fit="contain"
                    class="tu mr20"
                  ></el-image>
                  <div
                    class="flex_column pos ml20"
                    style="width: 480px; height: 480px"
                  >
                    <div class="flex_start flex_items mt40">
                      <div
                        class="f28 p5 mr5"
                        style="color: white; background: #007B46"
                      >
                        01
                      </div>
                      <span class="bold f18">自营连锁门店</span>
                    </div>
                    <p class="mt20 f15 f-left">
                      性价比高,及时可靠，15分钟响应，30分钟上门
                    </p>
                    <div class="flex_column abs" style="left: 0; bottom: 30px">
                      <div
                        class="f18 plr10 ptb5 mb30"
                        style="color: white; background: #d7ba7e; width: 206px"
                      >
                        全国连锁门店
                      </div>
                      <div class="flex_start flex_items">
                        <el-image
                          :src="imgUrl + 'about/tu2.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu3.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu4.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu5.png'"
                          class=""
                          style="width: 103px; height: 114px"
                        ></el-image>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 2 -->
                <div class="flex_start flex_items mt20">
                  <div
                    class="flex_column pos ml20"
                    style="width: 480px; height: 480px"
                  >
                    <div class="flex_start flex_items mt40">
                      <div
                        class="f28 p5 mr5"
                        style="color: white; background: #007B46"
                      >
                        02
                      </div>
                      <span class="bold f18">自有产业工人</span>
                    </div>
                    <p class="mt20 f15 f-left">
                      职业化打造，5年以上经验，施工专业，品质稳定责任心强
                    </p>
                    <div class="flex_column abs" style="left: 0; bottom: 30px">
                      <div
                        class="f18 plr10 ptb5 mb30"
                        style="color: white; background: #d7ba7e; width: 220px"
                      >
                        专业培训 实时考核
                      </div>
                      <div class="flex_start flex_items">
                        <el-image
                          :src="imgUrl + 'about/tu7.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu8.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu9.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu10.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                      </div>
                    </div>
                  </div>
                  <el-image
                    :src="imgUrl + 'about/tu6.png'"
                    fit="contain"
                    class="tu ml20"
                  ></el-image>
                </div>
                <!-- 3 -->
                <div class="flex_start flex_items mt20">
                  <el-image
                    :src="imgUrl + 'about/tu11.png'"
                    fit="contain"
                    class="tu mr20"
                  ></el-image>
                  <div
                    class="flex_column pos ml20"
                    style="width: 480px; height: 480px"
                  >
                    <div class="flex_start flex_items mt40">
                      <div
                        class="f28 p5 mr5"
                        style="color: white; background: #007B46"
                      >
                        03
                      </div>
                      <span class="bold f18">智能工单维保系统</span>
                    </div>
                    <p class="mt20 f15 f-left">
                      标准方案，透明报价，清单明细无增项
                    </p>
                    <div class="flex_column abs" style="left: 0; bottom: 30px">
                      <div
                        class="f18 plr10 ptb5 mb30"
                        style="color: white; background: #d7ba7e; width: 220px"
                      >
                        智能系统 闭口合同
                      </div>
                      <div class="flex_start flex_items">
                        <el-image
                          :src="imgUrl + 'about/tu12.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu13.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu14.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu15.png'"
                          class=""
                          style="width: 103px; height: 114px"
                        ></el-image>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 4 -->
                <!-- <div class="flex_start flex_items mt20">
                  <div
                    class="flex_column pos ml20"
                    style="width: 480px; height: 480px"
                  >
                    <div class="flex_start flex_items mt40">
                      <div
                        class="f28 p5 mr5"
                        style="color: white; background: #007B46"
                      >
                        04
                      </div>
                      <span class="bold f18">创新方案专利技术</span>
                    </div>
                    <p class="mt20 f15 f-left">
                      自主研发新型材料和技术，时间和费用仅为传统方案的六分之一
                    </p>
                    <div class="flex_column abs" style="left: 0; bottom: 30px">
                      <div
                        class="f18 plr10 ptb5 mb30"
                        style="color: white; background: #d7ba7e; width: 220px"
                      >
                        自主研发材料和技术
                      </div>
                      <div class="flex_start flex_items">
                        <el-image
                          :src="imgUrl + 'about/tu17.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu18.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu19.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                        <el-image
                          :src="imgUrl + 'about/tu20.png'"
                          class="mr20"
                          style="width: 103px; height: 114px"
                        ></el-image>
                      </div>
                    </div>
                  </div>
                  <el-image
                    :src="imgUrl + 'about/tu16.png'"
                    fit="contain"
                    class="tu ml20"
                  ></el-image>
                </div> -->
              </div>
            </div>
            <div class="mt30 flex_items flex_center ml20">
              <div class="flex_column" style="width: 1000px">
                <p class="bold f22 f-left">10秒获取房屋维修改造方案造价</p>
                <div class="">
                  <div class="flex_start flex_items mt20">
                    <el-form
                      :inline="true"
                      :model="formInline"
                      class="demo-form-inline"
                    >
                      <el-form-item>
                        <el-input
                          v-model="formInline.user"
                          placeholder="姓名"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="formInline.phone"
                          placeholder="联系方式"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-select
                          v-model="formInline.region"
                          placeholder="房屋类目"
                          clearable
                        >
                          <el-option
                            v-for="(item, index) in wens"
                            :key="index"
                            :label="item.name"
                            :value="item.category_id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item>
                        <el-button
                          type="primary"
                          @click="onSubmit"
                          style="background: #007B46; border: none"
                          >立即申请</el-button
                        >
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 招人 -->
        <!-- <div class="mt20"> -->
        <!-- <div class="flex_center flex_items">
            <div class="mt30 mb30" style="width: 1200px">
              <p class="bold f28">招贤纳士</p>
            </div>
          </div>
          <el-image
            :src="imgUrl + 'about/zp1.png'"
            fit="cover"
            style="width: 1100px; height: 411px"
          ></el-image>
          <div class="flex_center flex_items">
            <div class="mt20 flex_warp" style="width: 1100px">
              <div
                :class="[
                  'bgwhite',
                  'plr10',
                  'ptb20',
                  'flex_column',
                  'mt10',
                  'mr10',
                  'lian',
                ]"
                style="width: 340px"
                v-for="(item, index) in lists"
                :key="index"
                @click="lala(item)"
              > -->
        <!-- <div class="flex_between flex_items bold">
                  <span class="f16 f-left">{{ item.title }}</span>
                  <span class="f16 f-left" style="color: red">{{
                    item.salary
                  }}</span>
                </div> -->
        <!-- <div class="f14 flex_between flex_items pb10 bbe9s mt20">
                  <span class="f-left zhaodi" style="width: 180px">{{
                    item.place
                  }}</span>
                  <div>
                    <span class="mr10">招聘{{ item.count }}人</span>
                    <span>{{ item.education }}</span>
                  </div>
                </div> -->
        <!-- <div class="pb20 bbe9s flex_column f14 mt10 f-left">
                  <p>
                    岗位职责:
                    <span
                      v-html="item.duty_content"
                    ></span>
                  </p>
                  <p v-if="item.zt == 1" class="mt10">
                    任职资格: <span v-html="item.demand_content"></span>
                  </p>
                </div> -->
        <!-- <div class="f14 mt10 f-left">
                  <p>简历投递邮箱: {{ item.email }}</p>
                  <p class="mt10">联系电话: {{ item.phone }}</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 联系我们 -->
        <div class="women" style="margin-top: 70px">
          <div class="flex_center flex_items">
            <!-- <div class="mb30" style="width: 1200px">
              <p class="bold f34 mt100" style="color: white">联系我们</p>
            </div> -->
          </div>
          <div class="flex_center flex_items">
            <div style="width: 1300px" class="flex_start">
              <div class="flex_column" style="margin: 62px 50px 0 0"></div>
              <div class="flex_column" style="margin: 88px 0px 0px 50%">
                <div class="flex_start">
                  <div class="flex_column">
                    <p class="f28 f-left" style="color: #d3b779">与我们联系</p>
                    <el-form
                      :inline="true"
                      :model="formInline"
                      label-width="80px"
                      class="demo-form-inline mr30 mt20"
                    >
                      <el-row>
                        <el-col :span="8">
                          <el-form-item>
                            <el-input
                              v-model="formInline.user"
                              placeholder="姓名"
                            ></el-input> </el-form-item
                        ></el-col>
                        <el-col :span="8">
                          <el-form-item>
                            <el-input
                              v-model="formInline.phone"
                              placeholder="联系方式"
                            ></el-input> </el-form-item
                        ></el-col>
                        <el-col :span="8"
                          ><el-form-item>
                            <el-select
                              v-model="formInline.region"
                              placeholder="房屋类目"
                              clearable
                            >
                              <el-option
                                v-for="(item, index) in wens"
                                :key="index"
                                :label="item.name"
                                :value="item.category_id"
                              ></el-option>
                            </el-select> </el-form-item
                        ></el-col>
                      </el-row>
                    </el-form>
                    <el-input
                      type="textarea"
                      placeholder="问题描述(请叙述您的房屋问题情况)"
                      v-model="formInline.desc"
                      maxlength="100"
                      show-word-limit
                      style="width: 661px"
                      class="mt10"
                    >
                    </el-input>
                    <div class="flex_start flex_items mt20">
                      <el-image
                        :src="imgUrl + 'about/dianhua.png'"
                        style="width: 30px; height: 30px"
                        class="mr10"
                      ></el-image>
                      <p class="f14" style="color: #d3b779">
                        联系电话: 4000-987-009
                      </p>
                    </div>
                    <div class="flex_start flex_items mt10">
                      <el-image
                        :src="imgUrl + 'about/youxiang1.png'"
                        style="width: 30px; height: 30px"
                        class="mr10"
                      ></el-image>
                      <p class="f14" style="color: #d3b779">
                        邮箱地址: xuzhihui@yiniaoo2o.com
                      </p>
                    </div>
                    <el-button
                      @click="onSubmit()"
                      type="warning"
                      style="
                        background: #d3b779;
                        margin-top: 20px;
                        width: 661px;
                      "
                      >提交</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bgwhite mt90">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import { Toast } from "wot-design";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      wens: [],
      title: "",
      fan: "",
      addres: "",
      ss: 1,
      wen: "",
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
        desc: "",
      },
      lists: [],
    };
  },
  created() {
    this.$store.commit("setpage", "关于我们");
    // this.wens = JSON.parse(localStorage.getItem("wens"));
    this.getlei()
    this.getData();
    this.title = "成都益鸟科技有限公司";
    this.addres = "成都市金牛区万通金融广场西塔3137-3140";
    this.fan = "CHENG DU YI NIAO";
    this.wen =
      "益鸟创立于2016年3月，公司一直秉承客户价值第一的服务理念专注于解决装修后市场市场客户居住、办公的烦恼。5年";
    this.ss = 0;
    // if(window.location.host=="51yiniao.com"){
    //   this.title="上海益鸟焕新装饰工程有限公司";
    //   this.addres="上海【闵行店】闵行区水清路1050号"
    //   this.fan="SHANG HAI YI NIAO"
    //   this.wen="上海益鸟焕新装饰工程有限公司，成立于2021年8月，公司一直秉承客户价值第一的服务理念专注于解决装修后市场市场客户居住、办公的烦恼。近年来"
    //   this.ss=0
    // }else{
    //   this.title="成都益鸟科技有限公司";
    //    this.addres=" 成都市金牛区万通金融广场西塔3137-3140"
    //    this.fan="CHENG DU YI NIAO"
    //     this.wen="益鸟创立于2016年3月，公司一直秉承客户价值第一的服务理念专注于解决装修后市场市场客户居住、办公的烦恼。5年"
    // }
  },
  methods: {
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then((res) => {
        if (res.errcode == 200) {
          this.wens = res.data;
        }
      });
    },
    getData() {
      this.util.get(this.HOST + "/api/recruit", {}).then((res) => {
        if (res.errcode == 200) {
          for (let i = 0; i < res.data.lists.length; i++) {
            res.data.lists[i].zt = 0;
          }
          this.lists = res.data.lists;
        }
      });
    },
    lala(item) {
      localStorage.setItem("zhi", JSON.stringify(item));
      this.$router.push({
        path: "/recruit",
        query: {},
      });
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 3,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
  },
  components: {
    Header: header,
    Footer: footer,
  },
};
</script>
<style>
.aboutus .tt1 {
  width: 100%;
  height: 700px;
}
.aboutus .pp {
  width: 100%;
  height: 1000px;
  background-size: 100% 100%;
  background-position: 100% 100%;
  background: url("https://imgaes.yiniaoweb.com/gw/home/xpp.jpg") no-repeat;
}
.women {
  width: 100%;
  height: 720px;
  /* background: url("https://imgaes.yiniaoweb.com/gw/about/beijing1.png") */
  background: url("https://imgaes.yiniaoweb.com/gw/home/background.jpg")
    no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;

  margin-top: 30px;
}
.aboutus .tu {
  width: 480px;
  height: 480px;
}
.aboutus .lian {
  box-shadow: #ccc 0px 0px 10px;
  -webkit-box-shadow: #ccc 0px 0px 10px;
  -moz-box-shadow: #ccc 0px 0px 10px;
  cursor: pointer;
}
.xuanla {
  box-shadow: #ccc 0px 0px 10px;
  -webkit-box-shadow: #ccc 0px 0px 10px;
  -moz-box-shadow: #ccc 0px 0px 10px;
  height: auto;
  cursor: pointer;
}
.aboutus .lian:nth-child(3),
.aboutus .lian:nth-child(6),
.aboutus .lian:nth-child(9),
.aboutus .lian:nth-child(12),
.aboutus .lian:nth-child(15),
.aboutus .lian:nth-child(18) {
  margin-right: 0 !important;
}
.aboutus .el-textarea__inner {
  height: 130px !important;
  margin-bottom: 20px;
}
.zhaodi {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  width: 100%;
  -webkit-box-orient: vertical;
}
</style>
