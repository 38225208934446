<template>
  <div class="newsdetail">
    <el-backtop :visibility-height="60">
      <i class="el-icon-caret-top" style="margin: auto"></i>
    </el-backtop>
    <Header></Header>
    <!-- 搜索 -->
    <div class="ptb10 flex_center flex_items">
      <div class="flex_start" style="width: 1200px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/news' }"
            >新闻资讯</el-breadcrumb-item
          >
          <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="flex_center flex_items bgwhite ptb20">
      <div class="flex_start" style="width: 1200px">
        <div class="flex_column flex_items mr35" style="width: 800px">
          <div
            class="flex_center flex_items flex_column pb10 bbe9s"
            style="width: 800px"
          >
            <p class="bold f-22 f-center" style="width: 450px">
              {{ model.title }}
            </p>
          </div>
          <div class="flex_center flex_items" style="width: 800px">
            <div
              class="flex_between flex_items f12 mt15"
              style="width: 450px; color: #b3b3b3"
            >
              <span>发布时间: {{ model.created_at }}</span>
              <span>发布者：{{ model.author }}</span>
            </div>
          </div>
          <!-- 内容 -->
          <div
            v-if="model.textContent"
            class="f14 mt20 f20 wewe f-left"
            v-html="model.textContent.content"
          ></div>
        </div>
        <div class="flex_column flexx_items" style="width: 380px">
          <div class="flex_between flex_items">
            <div class="bold flex_start flex_items">
              <i class="el-icon-s-flag f18" style="color: red"></i>
              <span class="f16 mr5">热点咨询</span>
            </div>
            <span style="color: #8f8f8f; cursor: pointer" class="f14"
              >查看更多</span
            >
          </div>
          <div class="flex_column pb20 bbe9s">
            <div
              v-for="(item, index) in lists"
              :key="index"
              class="flex_start flex_items mt20"
              style="cursor: pointer"
              @click="chong(item.id)"
            >
              <img
                class="mr10"
                :src="tuhose + item.url"
                alt=""
                style="width: 120px; height: 93px"
              />
              <div class="flex_column pos" style="height: 93px; width: 234px">
                <p class="f16 f-left yila">{{ item.title }}</p>
                <p class="abs f14" style="left: 0; bottom: 0; color: #b3b3b3">
                  {{ item.browse }}阅读 · {{ item.created_at }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div class="flex_column flex_items mt15">
              <p class="f26 bold mt10">立即预约 极速上门检测</p>
              <p class="mt10 f12">
                已经有100000+位业主选择我们，并完成了房屋维修
              </p>
              <div class="flex_column" style="color: white; width: 320px">
                <el-input
                  placeholder="您的姓名"
                  prefix-icon="el-icon-user-solid"
                  v-model="formInline.user"
                  class="mt10 inin"
                >
                </el-input>
                <el-input
                  placeholder="手机号码"
                  prefix-icon="el-icon-phone"
                  v-model="formInline.phone"
                  class="mt10 inin"
                >
                </el-input>
                <el-button
                  @click="onSubmit"
                  type="warning"
                  class="mt10"
                  style="margin-top: 15px"
                  >立即预约</el-button
                >
                <div
                  class="p10 flex_start flex_items"
                  style="color: white; background: #007B46; margin-top: 40px"
                >
                  <img
                    class="mr5"
                    :src="imgUrl + 'product/jubu/dianhua.png'"
                    alt=""
                    style="width: 60px; height: 60px"
                  />
                  <div class="flex_column">
                    <p class="f14 f-left">客服咨询热线</p>
                    <p class="f22 bold">4000-987-009</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import { Toast } from "wot-design";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      formInline: {
        user: "",
        phone: "",
        region: "",
        xiaoqu: "",
      },
      id: "",
      lists: [],
      model: {},
    };
  },
  mounted() {
    this.$store.commit("setpage", "新闻资讯");
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/api/article_Info", {
          id: this.id,
        })
        .then((res) => {
          if (res.errcode == 200) {
            // res.data.model.textContent.content = this.HTMLDecode(
            //   res.data.model.textContent.content
            // );
            this.model = res.data.model;
            this.lists = res.data.new_articles.data;
          }
        });
    },
    HTMLDecode(text) {
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
    chong(id) {
      this.$store.commit("setpage", "新闻资讯");
      this.id = id;
      this.getData();
    },
    onSubmit() {
      this.$toast.loading({
        loadingType: "spinner",
        msg: "提交中...",
      });
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 1,
          name: this.formInline.user,
          mobile: this.formInline.phone,
          category_id: this.formInline.region,
          describe: this.formInline.xiaoqu,
          area: this.formInline.area,
        })
        .then((res) => {
          if (res.errcode == 200) {
            this.$toast.close();
            this.$toast.success("提交成功");
          } else {
            this.$toast.close();
            Toast.error(res.msg);
          }
        });
    },
  },
  components: {
    Header: header,
    Footer: footer,
  },
};
</script>
<style>
.yila {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.wewe img {
  margin: 10px 0;
  width: 50%;
  /* height: 500px; */
}
.wewe p {
  text-align: left !important;
}
</style>
