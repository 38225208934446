<template>
  <div class="recruit">
    <!-- backtop -->
    <el-backtop :visibility-height="60">
      <i class="el-icon-caret-top" style="margin: auto"></i>
    </el-backtop>
    <Header></Header>
    <div class="flex_center flex_items">
      <div style="width: 1100px" class="flex_column">
        <div class="f-left" style="padding:50px 0">
          <div class="flex_between flex_items">
            <span class="bold f36">{{ lists.title }}</span>
            <span class="f34" style="color: red">{{ lists.salary }}</span>
          </div>
          <p class="mt30" style="color: #8d8d8d">
            <span class="mr30">{{ lists.place }}</span>
            <span class="mr30">招{{ lists.count }}人</span>
            <span>{{ lists.education }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="bgwhite flex_center pt30" style="padding-bottom: 200px">
      <div style="width: 1000px" class="flex_column">
        <p class="bold f24 f-left mt20">岗位职责</p>
        <div class="f-left mt20 gang" v-html="lists.duty_content"></div>
        <p class="bold f24 f-left mt30">任职资格</p>
        <div class="f-left mt20 gang" v-html="lists.demand_content"></div>
        <p class="bold f24 f-left mt30">工作地点</p>
        <div class="f-left mt20 gang">{{ lists.place }}</div>
        <p class="bold f24 f-left mt30">联系方式</p>
        <p class="f-left mt20">简历投递邮箱：{{ lists.email }}</p>
        <p class="f-left mt20">联系电话：{{ lists.phone }}</p>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
export default {
  data() {
    return {
      lists: {},
    };
  },
  mounted() {
    this.$store.commit("setpage", "关于我们");
    this.lists = JSON.parse(localStorage.getItem("zhi"));
  },
  methods: {},
  components: {
    Header: header,
    Footer: footer,
  },
};
</script>
<style>
.gang p {
  margin-top: 15px;
}
</style>