<template>
  <div class="team">
    <!-- backtop -->
    <el-backtop :visibility-height="60">
      <i class="el-icon-caret-top" style="margin: auto"></i>
    </el-backtop>
    <Header></Header>
    <div>
      <el-image
        :src="imgUrl + 'home/bb.jpg'"
        fit="cover"
        class="tt1"
      ></el-image>
    </div>
    <div>
      <!-- 搜索 -->
      <div class="ptb10 flex_center flex_items">
        <div class="flex_start" style="width: 1200px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>益鸟团队</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- list -->
    <div class="flex_center flex_items bgwhite">
      <div class="flex_column f13" style="width: 1200px">
        <div class="flex_start flex_items ptb10 bbe9s">
          <div class="ptb5 mr10 f-left ss">职位筛选</div>
          <div
            v-for="(item, index) in titles"
            :key="index"
            :class="[
              'ptb5',
              'mr10',
              'ss',
              index == choseone ? 'chose1' : 'no1',
            ]"
            @click="chose1(index, item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex_center flex_items mt15 pb30" v-loading="loading2">
      <div v-if="list.length != 0" style="width: 1200px">
        <div class="flex_warp flex_items">
          <div
            class="mr10 mt10 bbe9s"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="pos renwu">
              <el-image
                :src="imgUrl + 'home/biaoqian.png'"
                class="biaoqian"
                fit="contain"
              ></el-image>
              <el-image
                :src="tuhose + item.url"
                class="rentu"
                fit="cover"
              ></el-image>
              <div class="abs dibu f-center">立即撩他，询问报价~</div>
            </div>
            <div class="flex_column f-left p10">
              <div class="flex_start">
                <span class="bold f15 mr5">{{ item.name }}</span>
                <span class="f12 mt5">{{ item.work_experience }}年经验</span>
              </div>
              <div class="mt10">
                <div class="flex_start">
                  <span class="f12 mr5">专业等级:</span>
                  <el-rate
                    v-model="item.grade"
                    disabled
                    text-color="#ff9900"
                    class="f12"
                  >
                  </el-rate>
                </div>
              </div>
              <div class="mt5">
                <div class="flex_start">
                  <span class="f12 mr5">业主评分:</span>
                  <el-rate
                    v-model="item.owner_score"
                    disabled
                    text-color="#ff9900"
                    class="f12"
                  >
                  </el-rate>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex_start mt20 flex_items">
          <span class="demonstration mr20">
            总共
            <span style="color: red">{{ total }}</span> 条数据</span
          >
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="20"
            layout="prev, pager, next"
            :current-page.sync="page"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div v-else class="flex_center flex_items ptb20">
        <span class="f18">--暂无搜索结果--</span>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      loading2: true,
      page: 1,
      total: 0,
      choseone: 0,
      titles: [],
      list: [],
    };
  },
  created() {
    this.$store.commit("setpage", "益鸟团队");
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/api/work/", {
          work_type: this.choseone,
          page: this.page,
        })
        .then((res) => {
          if (res.errcode == 200) {
            res.data.work.unshift("不限");
            this.titles = res.data.work;
            this.list = res.data.workers.data;
            this.total = res.data.workers.total;
            this.loading2 = false;
          }
        });
    },
    chose1(idx, item) {
      this.loading2 = true;
      this.choseone = idx;
      this.page = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.loading2 = true;
      this.getData();
    },
  },
  components: {
    Header: header,
    Footer: footer,
  },
};
</script>
<style>
.team .tt1 {
  width: 100%;
  height: 700px;
}
.team .ss {
  width: 75px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
}
.team .no1 {
  color: #000;
  background: none;
}
.team .chose1 {
  color: white;
  background: #007b46;
}
.team .renwu {
  /* background: #272528; */
  /* width: 313px; */
}
.team .renwu:last-child {
  margin-right: 0 !important;
}
.team .biaoqian {
  position: absolute;
  left: -1px;
  top: 10px;
  width: 70px;
  height: 30px;
  z-index: 5;
}
.team .rentu {
  width: 280px;
  height: 280px;
  bottom: -4px;
}
.team .dibu {
  width: 100%;
  height: 36px;
  line-height: 36px;
  color: white;
  font-size: 14px;
  background: rgba(5, 113, 61, 0.6);
  bottom: 1px;
}
</style>
