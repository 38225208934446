var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yicase"},[_c('el-backtop',{attrs:{"visibility-height":60}},[_c('i',{staticClass:"el-icon-caret-top",staticStyle:{"margin":"auto"}})]),_c('Header'),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"main"},[_c('div',{},[_c('el-image',{staticClass:"tt1",attrs:{"src":_vm.imgUrl + 'home/nb.png',"fit":"cover"}})],1),_c('div',{staticClass:"ptb10 flex_center flex_items"},[_c('div',{staticClass:"flex_start",staticStyle:{"width":"1200px"}},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("益鸟案例")])],1)],1)]),_c('div',{staticClass:"flex_center flex_items bgwhite"},[_c('div',{staticClass:"flex_column f13",staticStyle:{"width":"1200px"}},[_c('div',{staticClass:"flex_start flex_items ptb10 bbe9s"},[_c('div',{staticClass:"ptb5 mr10 f-left ss"},[_vm._v("项目类型")]),_vm._l((_vm.ser1),function(item,index){return _c('div',{key:index,class:[
              'ptb5',
              'mr10',
              'ss',
              index == _vm.choseone ? 'chose1' : 'no1' ],on:{"click":function($event){return _vm.chose1(index, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),_c('div',{staticClass:"flex_start flex_items ptb10 bbe9s"},[_c('div',{staticClass:"ptb5 mr10 f-left ss"},[_vm._v("面积")]),_vm._l((_vm.ser2),function(item,index){return _c('div',{key:index,class:[
              'ptb5',
              'mr10',
              'ss',
              index == _vm.chosetwo ? 'chose1' : 'no1' ],on:{"click":function($event){return _vm.chose2(index, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),_c('div',{staticClass:"flex_warp flex_items ptb10 bbe9s"},[_c('div',{staticClass:"ptb5 mr10 f-left ss"},[_vm._v("施工店铺")]),_vm._l((_vm.ser4),function(item,index){return _c('div',{key:index,class:[
              'ptb5',
              'mr10',
              'ss',
              'mt10',
              index == _vm.chosefour ? 'chose1' : 'no1' ],staticStyle:{"width":"78px"},on:{"click":function($event){return _vm.chose4(index, item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),_c('div',{staticClass:"flex_start flex_items ptb10 bbe9s"},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true,"model":_vm.formInline}},[_c('el-form-item',{attrs:{"label":""}},[_c('el-input',{attrs:{"placeholder":"输入小区名称"},model:{value:(_vm.formInline.user),callback:function ($$v) {_vm.$set(_vm.formInline, "user", $$v)},expression:"formInline.user"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("搜索")])],1)],1)],1)])]),_c('div',{staticClass:"flex_center flex_items mt20"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading2),expression:"loading2"}],staticStyle:{"width":"1215px"}},[(_vm.list.length != 0)?_c('div',{staticClass:"flex_warp flex_items"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"flex_column mr5 mt10 bbe9s p10 bgwhite",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.casedetail(item.id)}}},[_c('el-image',{staticClass:"tuhose",attrs:{"src":_vm.tuhose + item.url,"fit":"cover"}}),_c('div',{staticClass:"flex_between flex_items mt10"},[_c('span',{staticClass:"bold f18"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"flex_start flex_items"},[_c('el-image',{staticClass:"mr5",staticStyle:{"width":"20px","height":"15px"},attrs:{"src":_vm.imgUrl + 'home/chakan.png'}}),_c('span',{staticClass:"f14 cc"},[_vm._v(_vm._s(item.views))])],1)]),_c('p',{staticClass:"cc f12 mt10 f-left"},[_vm._v(" "+_vm._s(item.name)+" | "+_vm._s(item.area)+"m² ")])],1)}),0):_c('div',{staticClass:"flex_center flex_items"},[_c('span',{staticClass:"f18"},[_vm._v("--暂无搜索结果--")])])])])]),_c('Footer',{staticClass:"mt60"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }